import React, { Fragment } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormLabel from '@material-ui/core/FormLabel';
import Collapse from '@material-ui/core/Collapse';
import { BeatLoader } from 'react-spinners';
import { components } from 'react-select';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { handleLimitOrderBuyFormChange } from '../store/actions/form';
import { placeBuyLimitAsync } from '../store/actions/user';
import Button from './Button';
import TagContainer from './TagContainer';
import { rlcToNano } from '../utils/maths';
import { NULL_ADDRESS } from '../utils/ethereum';
import {
  DEFAULT_STORAGE_PROVIDER,
  CALLBACK_STORAGE_PROVIDER,
} from '../utils/iexecSecrets';
import { useWalletManager } from '@iexec/react-wallet-manager';
import RlcDisplay from './RlcDisplay';

const debug = Debug('LimitBuy');

const stateToProps = (state) => ({
  hasInputError: state.limitOrderBuyForm.errors,
});
const Tooltip = connect(stateToProps)(
  ({ hasInputError, children, fieldName, className }) => {
    if (!!hasInputError[fieldName])
      return React.cloneElement(children, {
        error: true,
        className: className || 'hint--bottom',
        'aria-label': hasInputError[fieldName],
      });
    return children;
  },
);
const MyInput = (props) => {
  return <components.Input {...props} isHidden={false} />;
};

const styles = (theme) => ({
  box: {
    flex: '1 0 350px',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0 0 3rem 0',
    padding: '0 1rem',
    overflow: 'auto',
  },
  spacer: {
    flex: 1,
  },
  container: {
    flex: 2,
  },
  button: {
    width: '100%',
    textAlign: 'center',
  },
  buy: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  disabled: {
    cursor: 'not-allowed',
  },
  arrow: {
    marginLeft: '20px',
  },
  collapse: {
    display: 'flex',
    flex: '1 0 auto',
    width: '100%',
  },
  collapseWrapper: {
    width: '100%',
    paddingLeft: '20px',
  },
});

const customStyles = {
  container: (base, state) => ({
    ...base,
    flex: 2,
  }),
  control: (base, state) => ({
    ...base,
    color: '#fff',
    border: '1px solid #505050',
    borderRadius: '1em',
    minHeight: '2em',
    height: '2em',
    backgroundColor: 'transparent',
    boxShadow: 0,
    cursor: 'text',
    ':hover': {
      border: '1px solid #505050',
    },
  }),
  valueContainer: (base, state) => ({
    ...base,
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 0,
  }),
  input: (base, state) => ({
    ...base,
    color: '#fff',
    opacity: 1,
    width: 0,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: '#fff',
  }),
  option: (base, state) => ({
    ...base,
    borderBottom: '1px solid #505050',
    backgroundColor: '#111111',
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: '1.9em',
    cursor: 'default',
  }),
  clearIndicator: (base, state) => ({
    ...base,
    padding: '8px 4px',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    color: '#505050',
    ':hover': {
      color: '#505050',
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '8px 4px',
    color: '#505050',
    ':hover': {
      color: '#505050',
    },
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: 3,
    marginBottom: 0,
    border: '1px solid #505050',
    borderRadius: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const LimitBuy = ({
  isAdvancedParamsOpen,
  isLoading,
  classes,
  dappAddress,
  dappPrice,
  datasetAddress,
  datasetPrice,
  paramsArgs,
  paramsInputFiles,
  paramsStorageProvider,
  paramsResultEncryption,
  volume,
  trust,
  tag,
  callbackAddress,
  workerpoolPrice,
  beneficiaryAddress,
  handleDappAddressChange,
  handleDappPriceChange,
  handleDatasetAddressChange,
  handleDatasetPriceChange,
  handleWorkerpoolPriceChange,
  handleParamsArgsChange,
  handleParamsInputFilesChange,
  handleParamsStorageProviderChange,
  handleParamsResultEncryptionChange,
  handleVolumeChange,
  handleTrustChange,
  handleTagChange,
  handleBeneficiaryChange,
  handleCallbackChange,
  toggleAdvancedParams,
  category,
  hasInputError,
  placeBuyLimit,
  dapps,
  datasets,
}) => {
  const { address, chainId } = useWalletManager();

  const isBuyFormValid =
    !hasInputError.DAPP_ADDRESS &&
    !hasInputError.DAPP_PRICE &&
    !hasInputError.DATASET_ADDRESS &&
    !hasInputError.DATASET_PRICE &&
    !hasInputError.WORKERPOOL_PRICE &&
    !hasInputError.BENEFICIARY_ADDRESS &&
    !hasInputError.PARAMS_ARGS &&
    paramsInputFiles.filter(
      (value, index) => !!hasInputError[`PARAMS_INPUT_FILES_${index}`],
    ).length === 0 &&
    !hasInputError.PARAMS_INPUT_FILES &&
    !hasInputError.PARAMS_STORAGE_PROVIDER &&
    !hasInputError.PARAMS_ENCRYPT_RESULT &&
    !hasInputError.VOLUME &&
    !hasInputError.TRUST &&
    !hasInputError.TAG &&
    !hasInputError.CALLBACK &&
    !(
      paramsStorageProvider === CALLBACK_STORAGE_PROVIDER && !callbackAddress
    ) &&
    !!dappAddress &&
    !!dappAddress.value &&
    !!dappPrice &&
    !!datasetAddress.value &&
    !!datasetPrice &&
    !!workerpoolPrice &&
    !!volume &&
    !!tag;
  const confirmPlace = () => {
    if (isBuyFormValid) {
      placeBuyLimit({
        appAddress: dappAddress.value,
        appPrice: rlcToNano(dappPrice),
        datasetAddress: datasetAddress.value,
        datasetPrice: rlcToNano(datasetPrice),
        beneficiaryAddress: beneficiaryAddress.value || address,
        workerpoolPrice: rlcToNano(workerpoolPrice),
        volume,
        workParams: {
          args: paramsArgs,
          inputFiles: paramsInputFiles,
          resultEncryption: paramsResultEncryption,
          storageProvider: paramsStorageProvider,
        },
        category,
        trust,
        tag,
        callback: callbackAddress,
      });
    }
  };
  const handleKeyboardSubmit = (fnName) => (e) => {
    if (e.which === 13 && e.nativeEvent.ctrlKey) {
      debug('ctrl + enter');
      if (fnName === 'placeOrder') {
        confirmPlace();
      }
    }
  };

  const callbackEnabled = paramsStorageProvider === CALLBACK_STORAGE_PROVIDER;

  return (
    <div className={classes.box}>
      <Tooltip fieldName="WORKERPOOL_PRICE">
        <FormControl required>
          <FormLabel>{'Workerpool Price:'}</FormLabel>
          <Input
            value={workerpoolPrice}
            onChange={handleWorkerpoolPriceChange}
            autoComplete="on"
            endAdornment={
              <InputAdornment position="end">
                <RlcDisplay />
              </InputAdornment>
            }
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="DAPP_ADDRESS" className="hint--top">
        <FormControl required>
          <FormLabel>{'Dapp Address:'}</FormLabel>
          <CreatableSelect
            name="dapp-address"
            className="Dapp"
            classNamePrefix="dapp"
            placeholder={'or name...'}
            isClearable
            inputValue={dappAddress.label}
            onChange={handleDappAddressChange}
            onInputChange={handleDappAddressChange}
            styles={customStyles}
            options={dapps
              .filter((dapp) => chainId in dapp.addresses)
              .map((dapp) => ({
                value: dapp.addresses[chainId],
                label: dapp.name,
                params: (dapp && dapp.buyConf && dapp.buyConf.params) || '',
              }))}
            components={{ Input: MyInput }}
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="DAPP_PRICE">
        <FormControl required>
          <FormLabel>{'Dapp Price:'}</FormLabel>
          <Input
            value={dappPrice}
            onChange={handleDappPriceChange}
            autoComplete="on"
            endAdornment={
              <InputAdornment position="end">
                <RlcDisplay />
              </InputAdornment>
            }
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="DATASET_ADDRESS" className="hint--top">
        <FormControl>
          <FormLabel>{'Dataset Address:'}</FormLabel>
          <CreatableSelect
            name="dataset-address"
            className="Dataset"
            classNamePrefix="dataset"
            placeholder={'or name...'}
            isClearable
            inputValue={datasetAddress.label}
            onChange={handleDatasetAddressChange}
            onInputChange={handleDatasetAddressChange}
            styles={customStyles}
            options={datasets
              .filter((dataset) => chainId in dataset.addresses)
              .map((dataset) => ({
                value: dataset.addresses[chainId],
                label: dataset.name,
              }))}
            components={{ Input: MyInput }}
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="DATASET_PRICE">
        <FormControl>
          <FormLabel>{'Dataset Price:'}</FormLabel>
          <Input
            value={datasetPrice}
            onChange={handleDatasetPriceChange}
            autoComplete="on"
            endAdornment={
              <InputAdornment position="end">
                <RlcDisplay />
              </InputAdornment>
            }
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>

      <Tooltip fieldName="PARAMS_ARGS">
        <FormControl>
          <FormLabel>{'Arguments:'}</FormLabel>
          <Input
            value={paramsArgs}
            placeholder={'app args'}
            onChange={handleParamsArgsChange}
            onKeyDown={handleKeyboardSubmit('fillOrder')}
          />
        </FormControl>
      </Tooltip>

      <FormControl>
        <FormLabel onClick={toggleAdvancedParams}>
          <span>Advanced parameters</span>
          {isAdvancedParamsOpen ? (
            <FaAngleUp className={classes.arrow} />
          ) : (
            <FaAngleDown className={classes.arrow} />
          )}
        </FormLabel>
      </FormControl>
      <Collapse
        in={isAdvancedParamsOpen}
        timeout="auto"
        classes={{
          root: classes.collapse,
          wrapper: classes.collapseWrapper,
        }}
      >
        <Tooltip fieldName="VOLUME">
          <FormControl required>
            <FormLabel>{'Volume:'}</FormLabel>
            <Input
              value={volume}
              onChange={handleVolumeChange}
              autoComplete="on"
              onKeyDown={handleKeyboardSubmit('placeOrder')}
            />
          </FormControl>
        </Tooltip>

        <Tooltip fieldName="PARAMS_INPUT_FILES">
          <FormLabel>{'Input files:'}</FormLabel>
          {paramsInputFiles.concat(['']).map((url, i) => (
            <Fragment key={i}>
              <Tooltip fieldName={`PARAMS_INPUT_FILES_${i}`}>
                <FormControl margin="dense">
                  <div className={classes.fixedSpacer} />
                  <FormLabel>{`file [${i}]:`}</FormLabel>
                  <Input
                    value={url}
                    placeholder={'file URL'}
                    onChange={handleParamsInputFilesChange(i)}
                    onKeyDown={handleKeyboardSubmit('placeOrder')}
                  />
                </FormControl>
              </Tooltip>
            </Fragment>
          ))}
        </Tooltip>

        <Tooltip fieldName="BENEFICIARY_ADDRESS" className="hint--top">
          <FormControl>
            <FormLabel>{'Beneficiary:'}</FormLabel>
            <CreatableSelect
              name="beneficiary-address"
              className="beneficiary"
              classNamePrefix="beneficiary"
              placeholder={'or address...'}
              isClearable
              inputValue={beneficiaryAddress.label}
              onChange={handleBeneficiaryChange}
              onInputChange={handleBeneficiaryChange}
              styles={customStyles}
              options={[
                {
                  value: NULL_ADDRESS,
                  label: 'Public',
                },
                {
                  value: address,
                  label: 'Private',
                },
              ]}
              components={{ Input: MyInput }}
              onKeyDown={handleKeyboardSubmit('placeOrder')}
            />
          </FormControl>
        </Tooltip>

        <Tooltip fieldName="TAG">
          <FormControl onKeyDown={handleKeyboardSubmit('placeOrder')}>
            <FormLabel>{'Tag:'}</FormLabel>
            <TagContainer
              className={classes.container}
              tag={tag}
              onTagChange={handleTagChange}
            />
          </FormControl>
        </Tooltip>

        <Tooltip fieldName="PARAMS_STORAGE_PROVIDER" className="hint--top">
          <FormControl>
            <FormLabel>{'Result storage:'}</FormLabel>
            <Select
              name="result-storage"
              placeholder={'choose provider'}
              onChange={handleParamsStorageProviderChange}
              onInputChange={handleParamsStorageProviderChange}
              styles={customStyles}
              options={[
                DEFAULT_STORAGE_PROVIDER,
                'dropbox',
                CALLBACK_STORAGE_PROVIDER,
              ].map((provider) => ({
                value: provider,
                label: provider,
              }))}
              isClearable
              components={{ Input: MyInput }}
              onKeyDown={handleKeyboardSubmit('placeOrder')}
            />
          </FormControl>
        </Tooltip>

        <Tooltip fieldName="CALLBACK">
          <FormControl
            disabled={!callbackEnabled}
            aria-label={
              !callbackEnabled
                ? `choose ${CALLBACK_STORAGE_PROVIDER} storage to trigger callback`
                : undefined
            }
            className={!callbackEnabled ? 'hint--top' : ''}
            required={paramsStorageProvider === CALLBACK_STORAGE_PROVIDER}
          >
            <FormLabel>{'Callback:'}</FormLabel>
            <Input
              value={callbackAddress}
              onChange={handleCallbackChange}
              placeholder={'contract address'}
              onKeyDown={handleKeyboardSubmit('placeOrder')}
            />
          </FormControl>
        </Tooltip>

        <Tooltip fieldName="TRUST">
          <FormControl>
            <FormLabel>{'Trust:'}</FormLabel>
            <Input
              value={trust}
              onChange={handleTrustChange}
              placeholder={'trust level (integer)'}
              onKeyDown={handleKeyboardSubmit('placeOrder')}
            />
          </FormControl>
        </Tooltip>
      </Collapse>
      <Button
        onClick={confirmPlace}
        aria-label="fill in buy form first"
        className={classNames(
          classes.button,
          classes.buy,
          !isBuyFormValid && classes.disabled,
          !isBuyFormValid && 'hint--top',
        )}
      >
        {isLoading ? (
          <BeatLoader size="0.4em" color="#000" />
        ) : (
          'Place computation buy limit order'
        )}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdvancedParamsOpen: state.limitOrderBuyForm.isAdvancedParamsOpen,
  dappAddress: state.limitOrderBuyForm.dappAddress,
  dappPrice: state.limitOrderBuyForm.dappPrice,
  datasetAddress: state.limitOrderBuyForm.datasetAddress,
  datasetPrice: state.limitOrderBuyForm.datasetPrice,
  workerpoolPrice: state.limitOrderBuyForm.workerpoolPrice,
  beneficiaryAddress: state.limitOrderBuyForm.beneficiaryAddress,
  paramsArgs: state.limitOrderBuyForm.paramsArgs,
  paramsInputFiles: state.limitOrderBuyForm.paramsInputFiles,
  paramsStorageProvider: state.limitOrderBuyForm.paramsStorageProvider,
  paramsResultEncryption: state.limitOrderBuyForm.paramsResultEncryption,
  volume: state.limitOrderBuyForm.volume,
  trust: state.limitOrderBuyForm.trust,
  tag: state.limitOrderBuyForm.tag,
  callbackAddress: state.limitOrderBuyForm.callbackAddress,
  category: state.data.filters.category,
  hasInputError: state.limitOrderBuyForm.errors,
  isLoading: state.isLoading['PLACE_BUY_LIMIT_ORDER'],
  dapps: state.data.dapps,
  datasets: state.data.datasets,
});

const mapDispatchToProps = (dispatch) => ({
  handleDappAddressChange: (value, meta) => {
    debug('value', value);
    debug('meta', meta);
    if (meta && meta.action === 'input-change')
      dispatch(
        handleLimitOrderBuyFormChange('DAPP_ADDRESS')({ value, label: value }),
      );
    if (meta && meta.action === 'select-option') {
      dispatch(handleLimitOrderBuyFormChange('DAPP_ADDRESS')(value));
      if (value.params && value.params.iexec_args !== undefined) {
        dispatch(
          handleLimitOrderBuyFormChange('PARAMS_ARGS')(value.params.iexec_args),
        );
      }
    }
    if (meta && meta.action === 'clear') {
      dispatch(
        handleLimitOrderBuyFormChange('DAPP_ADDRESS')({
          value: '',
          label: '',
        }),
      );
      dispatch(handleLimitOrderBuyFormChange('PARAMS_ARGS')(''));
    }
  },
  handleDappPriceChange: (event) =>
    dispatch(handleLimitOrderBuyFormChange('DAPP_PRICE')(event.target.value)),
  handleDatasetAddressChange: (value, meta) => {
    debug('value', value);
    debug('meta', meta);
    if (meta && meta.action === 'input-change')
      dispatch(
        handleLimitOrderBuyFormChange('DATASET_ADDRESS')({
          value: value === '' ? NULL_ADDRESS : value,
          label: value,
        }),
      );
    if (meta && meta.action === 'select-option') {
      dispatch(handleLimitOrderBuyFormChange('DATASET_ADDRESS')(value));
    }
    if (meta && meta.action === 'clear') {
      dispatch(
        handleLimitOrderBuyFormChange('DATASET_ADDRESS')({
          value: NULL_ADDRESS,
          label: '',
        }),
      );
    }
  },
  handleBeneficiaryChange: (value, meta) => {
    if (meta && meta.action === 'input-change')
      dispatch(
        handleLimitOrderBuyFormChange('BENEFICIARY_ADDRESS')({
          value: value,
          label: value,
        }),
      );
    if (meta && meta.action === 'select-option') {
      dispatch(handleLimitOrderBuyFormChange('BENEFICIARY_ADDRESS')(value));
    }
    if (meta && meta.action === 'clear') {
      dispatch(
        handleLimitOrderBuyFormChange('BENEFICIARY_ADDRESS')({
          value: '',
          label: '',
        }),
      );
    }
  },
  handleDatasetPriceChange: (event) =>
    dispatch(
      handleLimitOrderBuyFormChange('DATASET_PRICE')(event.target.value),
    ),
  handleWorkerpoolPriceChange: (event) =>
    dispatch(
      handleLimitOrderBuyFormChange('WORKERPOOL_PRICE')(event.target.value),
    ),
  handleVolumeChange: (event) =>
    dispatch(handleLimitOrderBuyFormChange('VOLUME')(event.target.value)),
  handleParamsArgsChange: (event) =>
    dispatch(handleLimitOrderBuyFormChange('PARAMS_ARGS')(event.target.value)),
  handleParamsInputFilesChange: (index) => (event) =>
    dispatch(
      handleLimitOrderBuyFormChange('PARAMS_INPUT_FILES')({
        index,
        value: event.target.value,
      }),
    ),
  handleParamsStorageProviderChange: (value, meta) => {
    if (!value || value.value !== CALLBACK_STORAGE_PROVIDER) {
      dispatch(handleLimitOrderBuyFormChange('CALLBACK')(''));
    }
    if (meta && meta.action === 'select-option') {
      dispatch(
        handleLimitOrderBuyFormChange('PARAMS_STORAGE_PROVIDER')(value.value),
      );
    }
    if (meta && meta.action === 'clear') {
      dispatch(
        handleLimitOrderBuyFormChange('PARAMS_STORAGE_PROVIDER')(
          DEFAULT_STORAGE_PROVIDER,
        ),
      );
    }
  },
  handleParamsResultEncryptionChange: (event) =>
    dispatch(
      handleLimitOrderBuyFormChange('PARAMS_ENCRYPT_RESULT')(
        event.target.value,
      ),
    ),
  handleTagChange: (tag) => dispatch(handleLimitOrderBuyFormChange('TAG')(tag)),
  handleTrustChange: (event) =>
    dispatch(handleLimitOrderBuyFormChange('TRUST')(event.target.value)),
  handleCallbackChange: (event) =>
    dispatch(handleLimitOrderBuyFormChange('CALLBACK')(event.target.value)),
  toggleAdvancedParams: () =>
    dispatch(handleLimitOrderBuyFormChange('TOGGLE_ADVANCED')()),
  placeBuyLimit: (args) => dispatch(placeBuyLimitAsync.request(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(withStyles(styles)(LimitBuy)));
