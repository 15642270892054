import React from 'react';
import {
  MuiThemeProvider,
  createTheme as createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import { WalletManagerProvider } from '@iexec/react-wallet-manager';
import { Redirect, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'hint.css';
import NavBar from './NavBar';
import ControlBar from './ControlBar';
import Notifier from './Notifier';
import Drawer from './Drawer';
import Grid from './Grid';
import { mediaQueries } from '../utils/display';
import { INFURA_PROJECT_ID } from '../config';
import { multiWeb3 } from '../services/web3';
import Web3ProviderModal from '../components/Web3ProviderModal';
import AccountManagerModal from '../components/AccountManagerModal';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Overpass-Regular, sans-serif',
      'Montserrat-Regular, sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: 'Montserrat-Regular, sans-serif',
    },
    fontSize: 16,
  },
  palette: {
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
    secondary: {
      // light: '#D4DCEA',
      light: '#F0F0F0',
      main: '#6C6C6C',
      // dark: '#4F5C73', // auto
      contrastText: '#111111',
    },
    background: {
      default: '#000000',
      paper: '#111111',
      red: '#EF5353',
      green: '#11B15E',
      grey: '#6C6C6C',
      main: (opacity = '0.3') => `rgba(250,233,0,${opacity})`,
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: '#505050',
      link: '#3498db',
      focusLink: '#1d6fa5',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // chromium based
        '*::-webkit-scrollbar': {
          height: '10px',
          width: '10px',
        },
        '*::-webkit-scrollbar-track-piece': {
          backgroundColor: '#111111',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundClip: 'content-box',
          backgroundColor: '#F9C300',
          border: `3px solid #111111`,
          borderRadius: '5px',
        },
        // firefox
        '*': {
          scrollbarColor: `${'#F9C300'} #111111`,
          scrollbarWidth: 'thin',
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 0,
      },
      scrollButtons: {
        color: '#FAE900',
        flex: '0 0 24px',
      },
    },
    MuiTab: {
      root: {
        color: '#fff',
        minWidth: '72px !important',
        textTransform: 'unset',
        fontSize: '1em',
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#383838',
        fontSize: '1em',
      },
    },
    MuiFormControl: {
      root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        flex: '0 0 auto',
        margin: '0.7em 0px',
      },
      marginDense: {
        marginTop: '0.1em',
        marginBottom: '0.1em',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
    },
    MuiInput: {
      root: {
        color: '#fff',
        margin: 0,
        borderRadius: '1em',
        border: '1px solid #505050',
        minWidth: 0,
        minHeight: 0,
        flex: 2,
      },
      input: {
        padding: '0.2em 0.7em',
      },
      multiline: {
        '& div': {
          margin: '0.5em',
        },
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: '#505050',
        padding: '0 20px',
        height: 'inherit',
        borderLeft: '2px solid #505050',
        '& p': {
          color: '#C0C0C0',
        },
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true, // No more ripple, on the whole application 💣!
    },
    MuiInputLabel: {
      disableAnimation: true,
    },
  },
});

const appStyles = (theme) => ({
  appGrid: {
    width: '100vw',
    display: 'grid',
    gridGap: '8px',
    padding: '10px 8px 8px 8px',
    gridTemplateRows: '3em 3em 400px auto',
    gridTemplateAreas: `'header'
      'controls'
      'chart'
      'wob'
      'trades'
      'myboard'
      'buysell'`,
  },
  [mediaQueries.medium.up]: {
    appGrid: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '3em 3em 400px auto',
      gridTemplateAreas: `'header header'
        'controls controls'
        'chart chart'
        'wob trades'
        'myboard myboard'
        'buysell buysell'`,
    },
  },
  [mediaQueries.big.up]: {
    appGrid: {
      height: '100vh',
      gridTemplateColumns: '3fr 2fr 2fr',
      gridTemplateRows: '3em 3em 45vh auto',
      gridTemplateAreas: `'header header header'
        'controls controls controls'
        'chart wob trades'
        'myboard buysell buysell'`,
    },
  },
});

const App = withStyles(appStyles)(({ classes }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <WalletManagerProvider multiWeb3={multiWeb3} infura={INFURA_PROJECT_ID}>
      <Web3ProviderModal />
      <AccountManagerModal />
      <Notifier />
      <div className={classes.appGrid}>
        <Drawer />
        <Route component={NavBar} />
        <ControlBar />
        <Grid />
      </div>
    </WalletManagerProvider>
  </MuiThemeProvider>
));

const AppWrapper = () => (
  <Switch>
    <Route exact path="/" component={App} />
    <Redirect to="/" />
  </Switch>
);

export default AppWrapper;
