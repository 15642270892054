import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { ErrorBoundary } from './ErrorBoundary';
import OrderBookTable from './OrderBookTable';
import TradesChart from './TradesChart';
import TradesTable from './TradesTable';
import MyBoard from './MyBoard';
import Filters from './Filters';
import TradingBoard from './TradingBoard';

const styles = (theme) => ({
  box: {
    overflow: 'hidden',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    background: '#111111',
    borderRadius: 5,
    position: 'relative',
  },
  chartRoot: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
  },
});

const MainGrid = ({ classes }) => {
  return (
    <Fragment>
      <Fade in={true} timeout={1000}>
        <div
          style={{
            gridArea: 'chart',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ErrorBoundary name="Filters">
            <Filters />
          </ErrorBoundary>
          <ErrorBoundary name="Chart">
            <div className={`${classes.chartRoot} ${classes.paper}`}>
              <TradesChart />
            </div>
          </ErrorBoundary>
        </div>
      </Fade>
      <Fade in={true} timeout={1000}>
        <div
          className={`${classes.box} ${classes.paper}`}
          style={{ gridArea: 'wob' }}
        >
          <ErrorBoundary name="Orderbook">
            <OrderBookTable />
          </ErrorBoundary>
        </div>
      </Fade>
      <Fade in={true} timeout={1000}>
        <div
          className={`${classes.box} ${classes.paper}`}
          style={{ gridArea: 'trades' }}
        >
          <ErrorBoundary name="Trades">
            <TradesTable />
          </ErrorBoundary>
        </div>
      </Fade>
      <Fade in={true} timeout={1000}>
        <div
          className={`${classes.box} ${classes.paper}`}
          style={{ gridArea: 'myboard' }}
        >
          <ErrorBoundary name="MyBoard">
            <MyBoard />
          </ErrorBoundary>
        </div>
      </Fade>
      <Fade in={true} timeout={1000}>
        <div
          className={`${classes.box} ${classes.paper}`}
          style={{ gridArea: 'buysell' }}
        >
          {' '}
          <ErrorBoundary name="TradingBoard">
            <TradingBoard />
          </ErrorBoundary>
        </div>
      </Fade>
    </Fragment>
  );
};

export default withStyles(styles)(MainGrid);
