import React, { Component, Fragment } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { Title, Table } from './Table';
import RlcDisplay from './RlcDisplay';
import {
  handleMarketOrderBuyFormChange,
  handleMarketOrderSellFormChange,
} from '../store/actions/form';
import { findMostRecentIndex } from '../utils/filterAndSort';
import { NULL_ADDRESS } from '../utils/ethereum';

const debug = Debug('OrderBookTable');
debug('');

const styles = (theme) => ({
  askRemaining: {
    color: theme.palette.background.red,
  },
  bidRemaining: {
    color: theme.palette.background.green,
  },
  newOrderRow: {
    animationName: 'newOrderRow',
    animationDuration: '2s',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes newOrderRow': {
    from: { backgroundColor: theme.palette.background.main('0.7') },
    to: { backgroundColor: 'transparent' },
  },
  selectedOrder: {
    color: theme.palette.primary.main,
  },
  marketPrice: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '0.5em',
    marginBottom: '0.5em',
    color: theme.palette.background.main,
  },
  priceUp: {
    color: theme.palette.background.green,
  },
  priceDown: {
    color: theme.palette.background.red,
  },
});

class OrderBookTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asksArrLength: 0,
      askIndexToFlash: 0,
      needFlashAsk: false,
      bidsArrLength: 0,
      bidIndexToFlash: 0,
      needFlashBid: false,
    };
  }
  componentDidUpdate(prevProps) {
    const {
      asks,
      bids,
      setWorkerpoolOrderHash,
      setRequestOrderHash,
    } = this.props;
    if (prevProps.asks !== asks && asks[0])
      setWorkerpoolOrderHash(asks[0].orderHash);
    if (prevProps.bids !== bids && bids[0])
      setRequestOrderHash(bids[0].orderHash);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.asks.length - prevState.asksArrLength === 1) {
      const res = findMostRecentIndex(nextProps.asks);
      return {
        asksArrLength: nextProps.asks.length,
        askIndexToFlash: res.index,
        needFlashAsk: true,
        bidsArrLength: nextProps.bids.length || 0,
        bidIndexToFlash: 0,
        needFlashBid: false,
      };
    }
    if (nextProps.bids.length - prevState.bidsArrLength === 1) {
      const res = findMostRecentIndex(nextProps.bids);
      return {
        asksArrLength: nextProps.asks.length || 0,
        askIndexToFlash: 0,
        needFlashAsk: false,
        bidsArrLength: nextProps.bids.length,
        bidIndexToFlash: res.index,
        needFlashBid: true,
      };
    }
    return {
      asksArrLength: nextProps.asks.length || 0,
      askIndexToFlash: prevState.askIndexToFlash,
      needFlashAsk: prevState.needFlashAsk,
      bidsArrLength: nextProps.bids.length || 0,
      bidIndexToFlash: prevState.bidIndexToFlash,
      needFlashBid: prevState.needFlashBid,
    };
  }
  render() {
    const {
      classes,
      asks,
      bids,
      isLoadingOrders,
      isLoadingTrades,
      setWorkerpoolOrderHash,
      setRequestOrderHash,
      trades,
      selectedAsk,
      selectedBid,
    } = this.props;
    const {
      needFlashBid,
      bidIndexToFlash,
      needFlashAsk,
      askIndexToFlash,
    } = this.state;

    const getAskCellProps = (order, index) => ({
      className: classNames(
        needFlashAsk && index === askIndexToFlash && classes.newOrderRow,
        'hint--right',
        selectedAsk === order.orderHash && classes.selectedOrder,
      ),
      'aria-label': order.orderHash,
      onClick: () => {
        setWorkerpoolOrderHash(order.orderHash);
      },
    });

    const getBidCellProps = (order, index) => ({
      className: classNames(
        needFlashBid && index === bidIndexToFlash && classes.newOrderRow,
        'hint--bottom',
        selectedBid === order.orderHash && classes.selectedOrder,
      ),
      'aria-label': 'pre-fill order ID',
      onClick: () => setRequestOrderHash(order.orderHash),
    });

    const askColumns = [
      {
        header: 'Price',
        headerMaxValue: () => '0.000000000',
        cell: (e) => (
          <RlcDisplay amount={e.order.workerpoolprice} displayUnit={false} />
        ),
        getCellProps: getAskCellProps,
      },
      {
        header: 'Workerpool',
        headerMaxValue: () => NULL_ADDRESS,
        cell: (e) => e.order.workerpool,
        getCellProps: getAskCellProps,
      },
      {
        header: 'Vol',
        headerMaxValue: (data) => Math.max(...data.map((e) => e.remaining)),
        cell: (e) => e.remaining,
        className: classes.askRemaining,
        getCellProps: getAskCellProps,
      },
    ];

    const bidColumns = [
      {
        header: 'Price',
        headerMaxValue: () => '0.000000000',
        cell: (e) => (
          <RlcDisplay amount={e.order.workerpoolmaxprice} displayUnit={false} />
        ),
        getCellProps: getBidCellProps,
      },
      {
        header: 'Requester',
        headerMaxValue: () => NULL_ADDRESS,
        cell: (e) => e.order.requester,
        getCellProps: getBidCellProps,
      },
      {
        header: 'Vol',
        headerMaxValue: (data) => Math.max(...data.map((e) => e.volume)),
        cell: (e) => e.remaining,
        className: classes.bidRemaining,
        getCellProps: getBidCellProps,
      },
    ];

    const lastTradePrice = trades[0] && trades[0].workerpool.price;
    const priceUp =
      trades[1] && trades[0].workerpool.price > trades[1].workerpool.price;
    const priceDown =
      trades[1] && trades[0].workerpool.price < trades[1].workerpool.price;

    return (
      <Fragment>
        <Title>{'Order Book'}</Title>
        <Table
          data={asks}
          loading={isLoadingOrders}
          columns={askColumns}
          emptyContent={'No Sell Orders'}
          reverseContent={true}
          flexHeigh={true}
          noScroll={true}
        />
        <div
          className={classNames(
            classes.marketPrice,
            priceUp ? classes.priceUp : null,
            priceDown ? classes.priceDown : null,
          )}
        >
          {!isLoadingTrades && (lastTradePrice || lastTradePrice === 0) && (
            <Fragment>
              {'Last price: '}
              <RlcDisplay amount={lastTradePrice} />{' '}
            </Fragment>
          )}
          {!isLoadingTrades && priceUp && <FaArrowUp />}
          {!isLoadingTrades && priceDown && <FaArrowDown />}
          {!isLoadingTrades && !priceUp && !priceDown && ` `}
          {isLoadingTrades && 'Loading trades'}
        </div>
        <Table
          data={bids}
          loading={isLoadingOrders}
          columns={bidColumns}
          emptyContent={'No Buy Orders'}
          bottomHeader={true}
          flexHeigh={true}
          noScroll={true}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  asks: state.data.workerpoolOrders,
  bids: state.data.requestOrders,
  selectedAsk: state.marketOrderBuyForm.workerpoolOrderHash,
  selectedBid: state.marketOrderSellForm.requestOrderHash,
  trades: state.data.trades,
  isLoadingOrders: state.isLoading['GET_ORDERS'],
  isLoadingTrades: state.isLoading['GET_TRADES'],
});

const mapDispatchToProps = (dispatch) => ({
  setWorkerpoolOrderHash: (orderHash) => {
    dispatch(handleMarketOrderBuyFormChange('WORKERPOOLORDER_HASH')(orderHash));
  },
  setRequestOrderHash: (orderHash) =>
    dispatch(handleMarketOrderSellFormChange('REQUESTORDER_HASH')(orderHash)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(OrderBookTable));
