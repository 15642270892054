import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input/Input';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import { set } from '../store/actions/app';
import Button from './CatButton';
import TagContainer from './TagContainer';
import { CATEGORIES } from '../config';

const styles = (theme) => ({
  filterContainer: {
    display: 'flex',
    overflowX: 'auto',
    flex: '0 0 auto',
    margin: '0px 24px 10px 24px',
  },
  tags: {
    display: 'flex',
    padding: '12px 0',
  },
  trust: {
    paddingLeft: '12px',
    display: 'flex',
    width: '150px',
  },
});

const stateToProps = (state) => ({
  trust: state.data.filters.trust,
});
const Tooltip = connect(stateToProps)(
  ({ trust, children, fieldName, className }) => {
    const inputs = { trust };
    if (inputs[fieldName].error)
      return React.cloneElement(children, {
        error: true,
        className: className || 'hint--right',
        'aria-label': inputs[fieldName].error,
      });
    return children;
  },
);

const Filters = ({
  classes,
  setCategory,
  category,
  setTag,
  tag,
  setTrust,
  trust,
}) => {
  return (
    <Fragment>
      <Tabs
        value={CATEGORIES.find((cat) => cat.id === category)}
        variant="scrollable"
        scrollButtons="on"
      >
        {CATEGORIES.map((cat) => {
          return (
            <Tab
              disableRipple
              onClick={() => setCategory(cat.id)}
              key={cat.id}
              icon={
                <Button key={cat.id} selected={category === cat.id}>
                  {cat.name ? cat.name : 'Cat'.concat(cat.id)}
                </Button>
              }
              value={cat}
            />
          );
        })}
      </Tabs>
      <div className={classes.filterContainer}>
        <TagContainer
          tag={tag}
          onTagChange={setTag}
          className={classes.tags}
          style
        />
        <Tooltip fieldName="trust">
          <FormControl classes={{ root: classes.trust }}>
            <FormLabel>{'Trust'}</FormLabel>
            <Input value={trust.value} onChange={setTrust} />
          </FormControl>
        </Tooltip>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  category: state.data.filters.category,
  tag: state.data.filters.tag,
  trust: state.data.filters.trust,
});

const mapDispatchToProps = (dispatch) => ({
  setCategory: (catId) => dispatch(set.category(catId)),
  setTag: (tag) => dispatch(set.tag(tag)),
  setTrust: (event) => {
    dispatch(set.trust(event.target.value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Filters));
