export const cancelRequestOrderAsync = {
  request: (orderHash, order) => ({
    type: 'CANCEL_REQUEST_ORDER_REQUEST',
    orderHash,
    order,
  }),
  success: (orderHash) => ({ type: 'CANCEL_REQUEST_ORDER_SUCCESS', orderHash }),
  failure: (error) => ({ type: 'CANCEL_REQUEST_ORDER_FAILURE', error }),
  cancelled: () => ({ type: 'CANCEL_REQUEST_ORDER_CANCELLED' }),
};

export const cancelWorkerpoolOrderAsync = {
  request: (orderHash, order) => ({
    type: 'CANCEL_WORKERPOOL_ORDER_REQUEST',
    orderHash,
    order,
  }),
  success: (orderHash) => ({
    type: 'CANCEL_WORKERPOOL_ORDER_SUCCESS',
    orderHash,
  }),
  failure: (error) => ({ type: 'CANCEL_WORKERPOOL_ORDER_FAILURE', error }),
  cancelled: () => ({ type: 'CANCEL_WORKERPOOL_ORDER_CANCELLED' }),
};

export const confirmFillOrderAsync = {
  request: (orderID, appAddress) => ({
    type: 'CONFIRM_FILL_ORDER_REQUEST',
    orderID,
    appAddress,
  }),
  success: (dapp) => ({ type: 'CONFIRM_FILL_ORDER_SUCCESS', dapp }),
  failure: (error) => ({ type: 'CONFIRM_FILL_ORDER_FAILURE', error }),
  cancelled: () => ({ type: 'CONFIRM_FILL_ORDER_CANCELLED' }),
};

export const confirmBuyMarketAsync = {
  request: ({
    workerpoolOrderHash,
    appAddress,
    datasetAddress,
    beneficiaryAddress,
    volume,
    workParams,
    tag,
    trust,
    callback,
  }) => ({
    type: 'CONFIRM_BUY_MARKET_ORDER_REQUEST',
    workerpoolOrderHash,
    appAddress,
    datasetAddress,
    beneficiaryAddress,
    volume,
    workParams,
    tag,
    trust,
    callback,
  }),
  success: (appOrder, datasetOrder, workerpoolOrder, requestOrderToSign) => ({
    type: 'CONFIRM_BUY_MARKET_ORDER_SUCCESS',
    appOrder,
    datasetOrder,
    workerpoolOrder,
    requestOrderToSign,
  }),
  failure: (error) => ({ type: 'CONFIRM_BUY_MARKET_ORDER_FAILURE', error }),
  cancelled: () => ({ type: 'CONFIRM_BUY_MARKET_ORDER_CANCELLED' }),
};

export const makeBuyDealAsync = {
  request: (appOrder, datasetOrder, workerpoolOrder, requestOrderToSign) => ({
    type: 'BUY_DEAL_REQUEST',
    appOrder,
    datasetOrder,
    workerpoolOrder,
    requestOrderToSign,
  }),
  success: (txHash) => ({
    type: 'BUY_DEAL_SUCCESS',
    txHash,
  }),
  failure: (error) => ({ type: 'BUY_DEAL_FAILURE', error }),
  cancelled: () => ({ type: 'BUY_DEAL_CANCELLED' }),
};

export const confirmSellMarketAsync = {
  request: (requestOrderHash, workerpoolAddress, volume) => ({
    type: 'CONFIRM_SELL_MARKET_ORDER_REQUEST',
    requestOrderHash,
    workerpoolAddress,
    volume,
  }),
  success: (appOrder, datasetOrder, workerpoolOrderToSign, requestOrder) => ({
    type: 'CONFIRM_SELL_MARKET_ORDER_SUCCESS',
    appOrder,
    datasetOrder,
    workerpoolOrderToSign,
    requestOrder,
  }),
  failure: (error) => ({ type: 'CONFIRM_SELL_MARKET_ORDER_FAILURE', error }),
  cancelled: () => ({ type: 'CONFIRM_SELL_MARKET_ORDER_CANCELLED' }),
};

export const makeSellDealAsync = {
  request: (appOrder, datasetOrder, workerpoolOrderToSign, requestOrder) => ({
    type: 'SELL_DEAL_REQUEST',
    appOrder,
    datasetOrder,
    workerpoolOrderToSign,
    requestOrder,
  }),
  success: (txHash) => ({
    type: 'SELL_DEAL_SUCCESS',
    txHash,
  }),
  failure: (error) => ({ type: 'SELL_DEAL_FAILURE', error }),
  cancelled: () => ({ type: 'SELL_DEAL_CANCELLED' }),
};

export const placeBuyLimitAsync = {
  request: ({
    appAddress,
    appPrice,
    datasetAddress,
    datasetPrice,
    workerpoolPrice,
    volume,
    category,
    trust,
    tag,
    workParams,
    beneficiaryAddress,
    callback,
  }) => ({
    type: 'PLACE_BUY_LIMIT_ORDER_REQUEST',
    appAddress,
    appPrice,
    datasetAddress,
    datasetPrice,
    workerpoolPrice,
    volume,
    category,
    trust,
    tag,
    workParams,
    beneficiaryAddress,
    callback,
  }),
  success: (orderHash) => ({
    type: 'PLACE_BUY_LIMIT_ORDER_SUCCESS',
    orderHash,
  }),
  failure: (error) => ({ type: 'PLACE_BUY_LIMIT_ORDER_FAILURE', error }),
  cancelled: () => ({ type: 'PLACE_BUY_LIMIT_ORDER_CANCELLED' }),
};

export const placeSellLimitAsync = {
  request: ({ workerpoolAddress, price, volume, category, trust, tag }) => ({
    type: 'PLACE_SELL_LIMIT_ORDER_REQUEST',
    workerpoolAddress,
    price,
    volume,
    category,
    trust,
    tag,
  }),
  success: (orderHash) => ({
    type: 'PLACE_SELL_LIMIT_ORDER_SUCCESS',
    orderHash,
  }),
  failure: (error) => ({ type: 'PLACE_SELL_LIMIT_ORDER_FAILURE', error }),
  cancelled: () => ({ type: 'PLACE_SELL_LIMIT_ORDER_CANCELLED' }),
};

export const initStorageAsync = {
  request: ({
    storageProvider,
    storageToken,
    successAction,
    failureAction,
  }) => ({
    type: 'INIT_STORAGE_REQUEST',
    storageProvider,
    storageToken,
    successAction,
    failureAction,
  }),
  success: () => ({
    type: 'INIT_STORAGE_SUCCESS',
  }),
  failure: (error) => ({ type: 'INIT_STORAGE_FAILURE', error }),
  cancelled: () => ({ type: 'INIT_STORAGE_CANCELLED' }),
};
