import React, { Component } from 'react';
// import Debug from 'debug';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import Success from 'mdi-material-ui/CheckCircleOutline';
import Info from 'mdi-material-ui/InformationOutline';
import Close from 'mdi-material-ui/CloseCircleOutline';

// const debug = Debug('Notifier');

var style = {
  Containers: {
    DefaultStyle: {
      width: 400,
      padding: '0px 30px 10px',
    },
  },
  MessageWrapper: {
    DefaultStyle: {
      width: 300,
      margin: 0,
      padding: 0,
      overflowWrap: 'break-word',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      fontSize: '1em',
      alignItems: 'center',
      borderRadius: 5,
      borderTop: 0,
      padding: '10px 30px 10px 10px',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
    success: {
      background: 'linear-gradient(180deg, #0F6140 0%, #2B935E 100%)',
      color: '#40FF8E',
    },
    info: {
      background: 'linear-gradient(180deg, #6B6634 0%, #856906 100%)',
      color: '#F9C300',
    },
    error: {
      background: 'linear-gradient(180deg, #743138 0%, #691015 100%)',
      color: '#EE2020',
    },
  },
  Dismiss: {
    DefaultStyle: {
      width: '1em',
      height: '1em',
      fontSize: '1em',
      lineHeight: 1.1,
    },
    success: {
      color: '#0F6140',
      backgroundColor: '#40FF8E',
    },
    info: {
      color: '#6B6634',
      backgroundColor: '#F9C300',
    },
    error: {
      color: '#743138',
      backgroundColor: '#EE2020',
    },
  },
  Title: {
    success: {
      color: '#40FF8E',
    },
    info: {
      color: '#F9C300',
    },
    error: {
      color: '#EE2020',
    },
  },
};

const SuccessIcon = (
  <div style={{ margin: '0 1em 0 0em' }}>
    <Success size={30} />
  </div>
);
const InfoIcon = (
  <div style={{ margin: '0 1em 0 0em' }}>
    <Info />
  </div>
);
const ErrorIcon = (
  <div style={{ margin: '0 1em 0 0em' }}>
    <Close />
  </div>
);

class Notifier extends Component {
  constructor(props) {
    super(props);
    this._notificationSystem = null;
  }
  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }
  componentDidUpdate() {
    const { notification } = this.props;
    if (notification.level === 'success')
      Object.assign(notification, { children: SuccessIcon });
    if (notification.level === 'info')
      Object.assign(notification, { children: InfoIcon });
    if (notification.level === 'error')
      Object.assign(notification, { children: ErrorIcon });

    if (notification.title || notification.message) {
      this._notificationSystem.addNotification(notification);
    }
  }
  render() {
    return <NotificationSystem ref="notificationSystem" style={style} />;
  }
}

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default connect(mapStateToProps)(Notifier);
