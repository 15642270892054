import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import NetworkImage from '../assets/images/network.svg';
import rlcLogo from '../assets/images/rlc-light.svg';
import { getChainName } from '../utils/chain';

const RLCLogoStyles = (theme) => ({
  rlcLogo: {
    height: 'auto',
    width: '2em',
  },
});

const RLCLogo = withStyles(RLCLogoStyles)(({ classes }) => (
  <img className={classes.rlcLogo} src={rlcLogo} alt="iExec RLC logo" />
));

export const ChainName = connect((state) => ({
  chainId: state.chain.chainId,
}))(
  withStyles((theme) => ({}))(({ classes, chainId }) => (
    <div>{getChainName(chainId)}</div>
  )),
);

export const ModalTitle = withStyles((theme) => ({
  topSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
  },
  accountTitle: {
    fontSize: '2rem',
    margin: '0 10px',
  },
}))(({ classes, title }) => (
  <div className={classes.topSection}>
    <RLCLogo />
    <div className={classes.title}>
      <div className={classes.accountTitle}>{title}</div> <ChainName />
    </div>
  </div>
));

export const ModalPaper = withStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 'fit-content',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    color: theme.palette.common.black,
    borderRadius: 10,
    backgroundImage: `url(${NetworkImage})`,
    '& a': {
      color: theme.palette.common.black,
    },
  },
}))(
  React.forwardRef((props, ref) => {
    return (
      <div className={props.classes.paper} tabIndex={-1} ref={ref}>
        {props.children}
      </div>
    );
  }),
);
