import React, { Fragment } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Table, Title } from './Table';
import RlcDisplay from './RlcDisplay';
import { getChainKey } from '../utils/chain';
import { NULL_ADDRESS } from '../utils/ethereum';
import { EXPLORER_BASE_URL } from '../config';

const debug = Debug('TradesTable');
debug();

const styles = (theme) => ({
  priceUp: {
    animationName: 'priceUp',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes priceUp': {
    from: { backgroundColor: theme.palette.background.green },
    to: { backgroundColor: 'transparent' },
  },
  priceDown: {
    animationName: 'priceDown',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes priceDown': {
    from: { backgroundColor: theme.palette.background.red },
    to: { backgroundColor: 'transparent' },
  },
});

const TradesTable = ({ classes, trades, isLoading, chainId }) => {
  const getCellProps = (trade, index) => {
    const styleName =
      !!trades[index + 1] &&
      Number(trade.workerpool.price) <
        Number(trades[index + 1].workerpool.price)
        ? classes.priceDown
        : classes.priceUp;
    return {
      className: classNames(index === 0 ? styleName : null, 'hint--right'),
    };
  };

  const columns = [
    {
      header: 'Price',
      headerMaxValue: () => '0.000000000',
      cell: (e) => (
        <RlcDisplay amount={e.workerpool.price} displayUnit={false} />
      ),
      getCellProps,
    },
    {
      header: 'Time',
      headerMaxValue: () => '00:00:00',
      cell: (e) => new Date(e.blockTimestamp).toLocaleTimeString(),
      getCellProps,
    },
    {
      header: 'Workerpool',
      headerMaxValue: () => NULL_ADDRESS,
      cell: (e) => e.workerpool.pointer,
      getCellProps,
    },
    {
      header: 'Vol',
      headerMaxValue: (data) => Math.max(...data.map((e) => e.botSize)),
      cell: (e) => e.botSize,
      getCellProps,
    },
  ];

  return (
    <Fragment>
      <Title>{'Recent Trades'}</Title>
      <Table
        data={trades}
        loading={isLoading}
        columns={columns}
        emptyContent={'No Trades'}
        getRowProps={(trade, index) => ({
          onClick: () =>
            window.open(
              `${EXPLORER_BASE_URL}/${getChainKey(chainId)}/deal/${
                trade.dealid
              }`,
              '_blank',
            ),
        })}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  chainId: state.chain.chainId,
  trades: state.data.trades,
  isLoading: state.isLoading['GET_TRADES'],
});

export default connect(mapStateToProps)(withStyles(styles)(TradesTable));
