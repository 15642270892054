import React from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input/Input';
import FormLabel from '@material-ui/core/FormLabel';
import { BeatLoader } from 'react-spinners';
import Button from './Button';
import classNames from 'classnames';
import { handleMarketOrderSellFormChange } from '../store/actions/form';
import { confirmSellMarketAsync } from '../store/actions/user';

const debug = Debug('MarketSell');

const stateToProps = (state) => ({
  hasInputError: state.marketOrderSellForm.errors,
});
const Tooltip = connect(stateToProps)(
  ({ hasInputError, children, fieldName, className }) => {
    if (!!hasInputError[fieldName])
      return React.cloneElement(children, {
        error: true,
        className: className || 'hint--bottom',
        'aria-label': hasInputError[fieldName],
      });
    return children;
  },
);

const styles = (theme) => ({
  box: {
    flex: '1 0 350px',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0 0 3rem 0',
    padding: '0 1rem',
    overflow: 'auto',
  },
  spacer: {
    flex: 1,
  },
  button: {
    width: '100%',
    textAlign: 'center',
  },
  sell: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  disabled: {
    cursor: 'not-allowed',
  },
});

const MarketSell = ({
  classes,
  orderHash,
  handleOrderHashChange,
  workerpoolAddress,
  handleWorkerpoolAddressChange,
  volume,
  handleVolumeChange,
  hasInputError,
  confirmSellMarket,
  isLoading,
}) => {
  const isSellFormValid =
    !hasInputError.REQUESTORDER_HASH &&
    !hasInputError.WORKERPOOL_ADDRESS &&
    !hasInputError.VOLUME &&
    !!orderHash &&
    !!workerpoolAddress &&
    !!volume;
  const confirmFill = () => {
    if (isSellFormValid) {
      confirmSellMarket(orderHash, workerpoolAddress, volume);
    }
  };
  const handleKeyboardSubmit = (fnName) => (e) => {
    if (e.which === 13 && e.nativeEvent.ctrlKey) {
      debug('ctrl + enter');
      if (fnName === 'fillOrder') {
        confirmFill();
      }
    }
  };

  return (
    <div className={classes.box}>
      <Tooltip fieldName="REQUESTORDER_HASH">
        <FormControl>
          <FormLabel>{'Order Hash:'}</FormLabel>
          <Input
            value={orderHash}
            placeholder={'select from orderbook'}
            onChange={handleOrderHashChange}
            onKeyDown={handleKeyboardSubmit('fillOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="VOLUME">
        <FormControl required>
          <FormLabel>{'Volume:'}</FormLabel>
          <Input
            value={volume}
            onChange={handleVolumeChange}
            onKeyDown={handleKeyboardSubmit('fillOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="WORKERPOOL_ADDRESS">
        <FormControl required>
          <FormLabel>{'Workerpool address:'}</FormLabel>
          <Input
            value={workerpoolAddress}
            onChange={handleWorkerpoolAddressChange}
            onKeyDown={handleKeyboardSubmit('fillOrder')}
          />
        </FormControl>
      </Tooltip>
      <Button
        onClick={confirmFill}
        aria-label="fill in sell form first"
        className={classNames(
          classes.button,
          classes.sell,
          !isSellFormValid && classes.disabled,
          !isSellFormValid && 'hint--top',
        )}
      >
        {isLoading ? (
          <BeatLoader size="0.4em" color="#000" />
        ) : (
          'Sell computation at market price'
        )}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orderHash: state.marketOrderSellForm.requestOrderHash,
  workerpoolAddress: state.marketOrderSellForm.workerpoolAddress,
  volume: state.marketOrderSellForm.volume,
  category: state.data.filters.category,
  hasInputError: state.marketOrderSellForm.errors,
  isLoading: state.isLoading['CONFIRM_SELL_MARKET_ORDER'],
});

const mapDispatchToProps = (dispatch) => ({
  handleOrderHashChange: (event) =>
    dispatch(
      handleMarketOrderSellFormChange('REQUESTORDER_HASH')(event.target.value),
    ),
  handleWorkerpoolAddressChange: (event) =>
    dispatch(
      handleMarketOrderSellFormChange('WORKERPOOL_ADDRESS')(event.target.value),
    ),
  handleVolumeChange: (event) =>
    dispatch(handleMarketOrderSellFormChange('VOLUME')(event.target.value)),
  confirmSellMarket: (...args) =>
    dispatch(confirmSellMarketAsync.request(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(withStyles(styles)(MarketSell)));
