import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = (theme) => ({
  button: {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    borderRadius: '1.5em',
    cursor: 'pointer',
    display: 'flex',
    margin: '0px 20px 0 0',
    padding: '0.5em 1.5em',
    fontWeight: 'bold',
    lineHeight: '16px',
  },
  selected: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
});

const CatButton = ({ classes, onClick, selected, children }) => (
  <div
    className={classNames(classes.button, selected && classes.selected)}
    onClick={onClick}
  >
    {children}
  </div>
);

export default withStyles(styles)(CatButton);
