import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import BN from 'bn.js';
import classNames from 'classnames';
import { toggleMarketBuyConfirmModal } from '../store/actions/form';
import { makeBuyDealAsync } from '../store/actions/user';
import Button from './Button';
import RlcDisplay from './RlcDisplay';
import { ModalTitle, ModalPaper } from './CustomModal';
import { useWalletManager } from '@iexec/react-wallet-manager';

const styles = (theme) => ({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    color: theme.palette.common.black,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: 10,
  },
  buy: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    color: 'red',
  },
  cancel: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0.4rem 0',
  },
  mainSection: {
    margin: 20,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    minHeight: 50,
  },
});

const MarketBuyConfirmModal = ({
  classes,
  showModal,
  toggleConfirmModal,
  appOrder,
  datasetOrder,
  workerpoolOrder,
  requestOrderToSign,
  makeDeal,
}) => {
  const { stakeBalance } = useWalletManager();
  const makeDealError =
    !appOrder || !datasetOrder || !workerpoolOrder || !requestOrderToSign;
  const workerpoolCost = new BN(workerpoolOrder.workerpoolprice);
  const datasetCost = new BN(datasetOrder.datasetprice);
  const appCost = new BN(appOrder.appprice);
  const volume = new BN(requestOrderToSign.volume);
  const costPerWork = workerpoolCost.add(datasetCost).add(appCost);
  const totalCost = costPerWork.mul(volume);

  const handleBuy = () => {
    if (makeDealError) return;
    const args = [appOrder, datasetOrder, workerpoolOrder, requestOrderToSign];
    makeDeal(...args);
    toggleConfirmModal();
  };

  const body = (
    <div>
      <ModalTitle title="Trade Confirmation" />
      <div className={classes.section}>
        iExec Account: <RlcDisplay amount={stakeBalance} />
      </div>
      <div className={classes.mainSection}>
        <div className={classes.row}>
          <div>Worker Cost:</div>
          <RlcDisplay amount={workerpoolCost} />
        </div>
        <div className={classes.row}>
          <div>Dapp Cost:</div>
          <RlcDisplay amount={appCost} />
        </div>
        <div className={classes.row}>
          <div>Dataset Cost:</div>
          <RlcDisplay amount={datasetCost} />
        </div>
        <div className={classes.row}>
          <div>Cost per task:</div>
          <RlcDisplay amount={costPerWork} />
        </div>
        <div className={classes.row}>
          <div>Volume:</div>
          <div> {volume.toString()}</div>
        </div>
        <div className={classes.row}>
          <div>Total Execution Cost:</div>
          <RlcDisplay amount={totalCost} />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      open={showModal}
      className={classes.modal}
      onClose={toggleConfirmModal}
    >
      <ModalPaper>
        <div className={classes.main}>
          {body}
          <div className={classes.section}>
            <Button
              onClick={handleBuy}
              className={classNames(classes.button, classes.buy)}
              disabled={makeDealError}
            >
              {'Confirm Buy'}
            </Button>
            <Button
              onClick={toggleConfirmModal}
              className={classNames(classes.button, classes.cancel)}
            >
              {'Cancel'}
            </Button>
          </div>
        </div>
      </ModalPaper>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.marketOrderBuyForm.isConfirmModalOpen,
  appOrder: state.marketOrderBuyForm.preparedDeal.appOrder,
  datasetOrder: state.marketOrderBuyForm.preparedDeal.datasetOrder,
  workerpoolOrder: state.marketOrderBuyForm.preparedDeal.workerpoolOrder,
  requestOrderToSign: state.marketOrderBuyForm.preparedDeal.requestOrderToSign,
});

const mapDispatchToProps = (dispatch) => ({
  toggleConfirmModal: () => dispatch(toggleMarketBuyConfirmModal()),
  makeDeal: (...args) => dispatch(makeBuyDealAsync.request(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MarketBuyConfirmModal));
