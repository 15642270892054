import React, { Fragment } from 'react';
import Debug from 'debug';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Modal, LoginHint } from './Table';
import LimitBuy from './LimitBuy';
import LimitSell from './LimitSell';
import { useWalletManager } from '@iexec/react-wallet-manager';

const debug = Debug('LimitBuySell');
debug('');

const styles = (theme) => ({
  root: {
    display: 'flex',
    '@media (max-width: 1024px)': {
      display: 'block',
    },
    alignItems: 'stretch',
    height: '100%',
  },
  verticalSpacer: {
    '@media (max-width: 1024px)': {
      height: '0.5rem',
    },
  },
  separator: {
    backgroundColor: theme.palette.common.black,
    borderRadius: '100',
    minWidth: '0.5rem',
    minHeight: '0.5rem',
    margin: '0.5rem',
  },
});

const LimitBuySell = ({ classes, isLoggedIn }) => {
  const { connected } = useWalletManager();
  return (
    <Fragment>
      <Modal open={!connected}>
        <LoginHint />
      </Modal>
      <div className={classes.root}>
        <div className={classes.verticalSpacer} />
        <LimitBuy />
        <div className={classes.verticalSpacer} />
        <div className={classes.separator} />
        <div className={classes.verticalSpacer} />
        <LimitSell />
      </div>
    </Fragment>
  );
};

export default withTheme(withStyles(styles)(LimitBuySell));
