import React from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { notify } from '../store/actions/notifier';

const debug = Debug('ErrorBoundary');

class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    debug('error', error);
    debug('info', info);
    this.props.notifyError(error.message);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`Error with ${this.props.name}`}
        </div>
      );
    }
    return this.props.children;
  }
}

const errorDispatchToProps = (dispatch) => ({
  notifyError: (error) =>
    dispatch(
      notify({
        message: error,
        position: 'tc',
        level: 'error',
      }),
    ),
});

export const ErrorBoundary = connect(
  null,
  errorDispatchToProps,
)(ErrorBoundaryComponent);
