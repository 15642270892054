import { combineReducers } from 'redux';

function isDrawerOpen(state = false, action) {
  switch (action.type) {
    case 'TOGGLE_DRAWER':
      return !state;
    default:
      return state;
  }
}

function fadeOnceRenderCount(state = 0, action) {
  switch (action.type) {
    case 'INC_FADE_ONCE_RENDER_COUNT':
      return state + 1;
    default:
      return state;
  }
}

export default combineReducers({
  fadeOnceRenderCount,
  isDrawerOpen,
});
