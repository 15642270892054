import { combineReducers } from 'redux';
import { validateInt } from '../../utils/validation';
import { NULL_BYTES32 } from '../../utils/ethereum';
import { DEFAULT_CATEGORY } from '../../config';

function category(state = DEFAULT_CATEGORY, action) {
  switch (action.type) {
    case 'SET_CATEGORY':
      return action.category;
    default:
      return state;
  }
}

function tag(state = NULL_BYTES32, action) {
  switch (action.type) {
    case 'SET_TAG':
      return action.tag;
    default:
      return state;
  }
}

function trust(state = { value: '', error: '' }, action) {
  switch (action.type) {
    case 'SET_TRUST':
      const err = validateInt(action.trust);
      return err
        ? { value: action.trust, error: err }
        : { value: action.trust, error: '' };
    default:
      return state;
  }
}

export default combineReducers({
  category,
  tag,
  trust,
});
