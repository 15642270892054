import Big from 'big.js';
import { utils as ethersUtils } from 'ethers';

export const checksummedAddress = (address) =>
  ethersUtils.getAddress(address.toLowerCase());

const isInt = (value) => {
  if (/^(-|\+)?([0-9]+|Infinity)$/.test(value)) return true;
  return false;
};

export const validateInt = (str) => {
  if (!str) return '';
  if (!isInt(str)) return 'must be an integer';
  if (!(Number(str) >= 0)) return 'must be positive';
  return '';
};

export const validateRlcAmount = (str) => {
  if (!str) return '';
  let rlcBig;
  let nRlcBig;
  try {
    Big.NE = -10;
    Big.PE = 18;
    rlcBig = new Big(str.toString());
    const unitMultiplier = new Big(10).pow(9);
    nRlcBig = rlcBig.times(unitMultiplier);
  } catch (error) {
    return 'must be decimal number';
  }
  if (rlcBig.s === -1) return 'must be positive';
  if (rlcBig.gt(new Big(87000000))) return 'must not exceed 87000000';
  if (nRlcBig.gt(new Big(nRlcBig.toFixed(0))))
    return 'precision must not exceed 9 decimals';
  return '';
};

export const validateEthAmount = (str) => {
  if (!str) return '';
  let ethBig;
  let weiBig;
  try {
    Big.NE = -19;
    Big.PE = 18;
    ethBig = new Big(str.toString());
    const unitMultiplier = new Big(10).pow(18);
    weiBig = ethBig.times(unitMultiplier);
  } catch (error) {
    return 'must be decimal number';
  }
  if (ethBig.s === -1) return 'must be positive';
  if (weiBig.gt(new Big(weiBig.toFixed(0))))
    return 'precision must not exceed 18 decimals';
  return '';
};

export const validateAddress = (str) => {
  if (str === null || str === undefined) return '';
  if (/^(0x)?[0-9a-f]{40}$/i.test(str)) return '';
  if (typeof str === 'string' && str.endsWith('.eth')) return '';
  return 'must be a valid address or ENS';
};

export const isBytes32 = (bytes32) => /^(0x)?[0-9a-f]{64}$/i.test(bytes32);

export const validateBytes32 = (str) => {
  if (!str) return '';
  if (!isBytes32(str)) return 'must be Hex string of length 64';
  return '';
};

export const validateNotNullInt = (str) => {
  if (!str) return '';
  if (!isInt(str)) return 'must be an integer';
  if (!(Number(str) > 0)) return 'must be positive strict';
  return '';
};

export const validateOrder = (order) => {
  if (!order || !order.order) return 'no matching order found';
  return '';
};

export const isURL = (url) =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi.test(
    url,
  );

export const validateUrl = (str) => {
  if (!str) return '';
  if (!isURL(str)) return 'must be an URL';
};
