import React, { Component, Fragment } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { BarSeries, LineSeries } from 'react-stockcharts/lib/series';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import {
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY,
} from 'react-stockcharts/lib/coordinates';
import { last } from 'react-stockcharts/lib/utils';
import { fitDimensions } from 'react-stockcharts/lib/helper';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { ScaleLoader } from 'react-spinners';
import { Title } from './Table';

const debug = Debug('TradesChart');
debug('');
const styles = (theme) => ({
  label: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: 10,
  },
  noData: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
});

class TradesChart extends Component {
  render() {
    const {
      classes,
      ohlc,
      width,
      height,
      ratio,
      isLoading,
      theme,
    } = this.props;

    if (isLoading)
      return (
        <div className={classes.noData}>
          <ScaleLoader height={25} color={theme.palette.primary.main} />
        </div>
      );
    if (ohlc.length > 1) {
      const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
        (d) => d.blockTimestamp,
      );
      const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(
        ohlc,
      );
      const yLabelMaxLength = Math.max(
        ...data.map((d) => String(d.value).length),
      );

      const start = xAccessor(last(data));
      const end = xAccessor(data[Math.max(0, data.length - 150)]);
      const xExtents = [start, end];

      const margin = {
        left: 5,
        right: 10 + 8 * yLabelMaxLength,
        top: 20,
        bottom: 10,
      };
      const gridWidth = width - margin.left - margin.right;

      const innerTickSize = -1 * gridWidth;
      return (
        <Fragment>
          <div className={classes.label}>
            <Title>{`nRLC/Task`}</Title>
          </div>
          <ChartCanvas
            margin={margin}
            ratio={ratio}
            width={width}
            height={height}
            seriesName="MSFT"
            data={data}
            xScale={xScale}
            xAccessor={xAccessor}
            displayXAccessor={displayXAccessor}
            xExtents={xExtents}
            clamp="both"
          >
            <Chart
              id={0}
              yExtents={(d) => [d.volume, 0]}
              height={10}
              origin={(w, h) => [0, h - 10]}
            >
              <MouseCoordinateY
                at="left"
                orient="right"
                displayFormat={format('d')}
                dx={10}
              />

              <BarSeries
                yAccessor={(d) => d.volume}
                stroke={false}
                fill="#212020"
                opacity={1}
                widthRatio={0.95}
              />
            </Chart>
            <Chart id={1} yExtents={(d) => [d.value * 2, 0]}>
              <XAxis axisAt="bottom" orient="bottom" />
              <YAxis
                axisAt="right"
                orient="right"
                stroke="#1e1e1e"
                opacity={0}
                ticks={13}
                outerTickSize={1}
                tickStroke="#777777"
                innerTickSize={innerTickSize}
                tickStrokeDasharray="ShortDot"
                tickStrokeOpacity={0.2}
              />

              <MouseCoordinateX
                at="bottom"
                orient="top"
                displayFormat={timeFormat('%Y-%m-%d %H:%M:%S')}
                dx={10}
              />

              <LineSeries
                yAccessor={(d) => d.value}
                stroke={theme.palette.primary.main}
                strokeWidth={2}
              />
            </Chart>
            <CrossHairCursor stroke="#777777" />
          </ChartCanvas>
        </Fragment>
      );
    }
    return <div className={classes.noData}> No Chart </div>;
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading['GET_OHLC'],
  ohlc: state.data.ohlc,
  category: state.data.filters.category,
});

export default connect(mapStateToProps)(
  fitDimensions(withTheme(withStyles(styles)(TradesChart))),
);
