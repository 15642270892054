import { NULL_ADDRESS, NULL_BYTES32, NULL_BYTES } from './ethereum';
import { decodeTag, sumTags } from 'iexec/utils';

export const TEE_TAG =
  '0x0000000000000000000000000000000000000000000000000000000000000001';

export const NULL_DATASETORDER = {
  dataset: NULL_ADDRESS,
  datasetprice: 0,
  volume: 0,
  tag: NULL_BYTES32,
  apprestrict: NULL_ADDRESS,
  workerpoolrestrict: NULL_ADDRESS,
  requesterrestrict: NULL_ADDRESS,
  salt: NULL_BYTES32,
  sign: NULL_BYTES,
};

export const TAG_LABELS = {
  tee: 'TEE',
  gpu: 'GPU',
};

export const isTeeRequired = (...tags) => {
  const summedTags = [];
  tags.forEach((tag) => summedTags.push(...decodeTag(tag)));
  return summedTags.includes('tee');
};

export const validateTagRequirement = (tag, requiredTagsArray) => {
  const requiredTags = decodeTag(sumTags(requiredTagsArray));
  const availableTags = decodeTag(tag);
  const missingTags = requiredTags.reduce((acc, curr) => {
    if (!availableTags.includes(curr)) acc.push(curr);
    return acc;
  }, []);
  return missingTags.length === 0
    ? ''
    : `Missing tags: ${missingTags.map((e) => TAG_LABELS[e] || e)}`;
};
