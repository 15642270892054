import Debug from 'debug';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import chain from './chain';
import prices from './prices';
import accountManager from './accountManager';
import data from './data';
import app from './app';
import storage from './storage';
import marketOrderBuyForm from './marketOrderBuyForm';
import marketOrderSellForm from './marketOrderSellForm';
import limitOrderBuyForm from './limitOrderBuyForm';
import limitOrderSellForm from './limitOrderSellForm';

const debug = Debug('reducer');

function isLoading(state = {}, action) {
  const { type } = action;
  if (
    type.includes('_SUCCESS') ||
    type.includes('_FAILURE') ||
    type.includes('_CANCELLED')
  ) {
    debug('type', type);
    const scope = type
      .split('_SUCCESS')[0]
      .split('_FAILURE')[0]
      .split('_CANCELLED')[0];
    return Object.assign({}, state, {
      [scope]: false,
    });
  }
  if (type.includes('_REQUEST')) {
    const scope = type.split('_REQUEST')[0];
    return Object.assign({}, state, {
      [scope]: true,
    });
  }
  return state;
}

function errors(state = [], action) {
  const { type, error } = action;
  if (type.includes('_FAILURE')) {
    debug('Error', type, error);
    return [
      {
        timestamp: Date.now(),
        type,
        error,
      },
      ...state,
    ];
  }
  return state;
}

const defaultNotification = {
  message: '',
  position: 'tc',
  level: 'info',
  dismissible: 'button',
};

export function notification(state = defaultNotification, action) {
  const { type, notification, error } = action;
  if (type.includes('_FAILURE')) {
    return Object.assign({}, defaultNotification, {
      message: error,
      level: 'error',
    });
  } else if (type === 'NOTIFY') {
    return Object.assign({}, defaultNotification, {
      ...notification,
    });
  }
  return state;
}

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    chain,
    prices,
    accountManager,
    storage,
    app,
    data,
    marketOrderBuyForm,
    marketOrderSellForm,
    limitOrderBuyForm,
    limitOrderSellForm,
    notification,
    isLoading,
    errors,
  });

export default rootReducer;
