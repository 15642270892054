import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IoMdEye as Visible, IoMdEyeOff as Unvisible } from 'react-icons/io';
import { FaCloudUploadAlt as StorageIcon } from 'react-icons/fa';
import classNames from 'classnames';
import { BeatLoader } from 'react-spinners';
import Button from './Button';
import { ModalPaper } from './CustomModal';
import { capitalizeFirstLetter } from '../utils/format';
import { initStorageAsync } from '../store/actions/user';
import { closeStorageModal } from '../store/actions/storage';
import { DEFAULT_STORAGE_PROVIDER } from '../utils/iexecSecrets';

const styles = (theme) => ({
  modalLock: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    pointerEvents: 'none',
    zIndex: 2000, // notifications: 9998
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  input: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
  inputAdornment: {
    border: '0px',
    padding: 0,
  },
  button: {
    margin: 10,
    minWidth: '10em',
  },
  init: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  cancel: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
});

const Title = withStyles((theme) => ({
  topSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
  },
  icon: {
    margin: 'auto 0.5rem auto 0',
  },
  modalTitle: {
    fontSize: '2rem',
    margin: '0 10px',
  },
}))(({ classes, title }) => (
  <div className={classes.topSection}>
    <div className={classes.title}>
      <StorageIcon className={classes.icon} size="2rem" />
      <div className={classes.modalTitle}>{title}</div>
    </div>
  </div>
));

const StorageModal = ({
  classes,
  showModal,
  isLoading,
  storageProvider,
  initStorage,
  cancel,
  successAction,
  failureAction,
}) => {
  const [showToken, setShowToken] = useState(false);
  const [token, setToken] = useState('');

  const providerDisplayName = capitalizeFirstLetter(storageProvider);
  const body = (
    <div>
      <Title title="Remote Storage" />
      <div className={classes.section}>
        Your {providerDisplayName} storage is not yet initialized.
        <br />
        To ensure the storage of your results you must initialize the storage.
      </div>
      <div className={classes.section}>
        {storageProvider !== DEFAULT_STORAGE_PROVIDER && (
          <Input
            id="storage-token"
            className={classes.input}
            value={token}
            onChange={(event) => setToken(event.target.value)}
            type={showToken ? 'text' : 'password'}
            disabled={isLoading}
            placeholder={`Paste here your ${providerDisplayName} token`}
            endAdornment={
              <InputAdornment className={classes.inputAdornment} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowToken(!showToken)}
                >
                  {showToken ? <Visible /> : <Unvisible />}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </div>
    </div>
  );

  const initError =
    storageProvider !== DEFAULT_STORAGE_PROVIDER && !token
      ? 'paste your token first'
      : '';

  const handleInit = () => {
    if (!(isLoading || initError)) {
      initStorage({
        storageProvider,
        storageToken: token,
        successAction,
        failureAction,
      });
    }
  };
  const handleCancel = () => {
    if (!isLoading) {
      cancel(failureAction);
      setToken('');
    }
  };

  return (
    <Fragment>
      <div className={classNames(isLoading && classes.modalLock)} />
      <Modal open={showModal} className={classes.modal}>
        <ModalPaper>
          <div className={classes.main}>
            {body}
            <div className={classes.section}>
              <Button
                onClick={handleInit}
                className={classNames(
                  classes.button,
                  classes.init,
                  initError && 'hint--bottom',
                )}
                disabled={isLoading || initError}
                aria-label={initError}
              >
                {isLoading ? (
                  <BeatLoader size="0.4em" color="#000" />
                ) : (
                  'Initialize'
                )}
              </Button>
              <Button
                onClick={handleCancel}
                className={classNames(classes.button, classes.cancel)}
                disabled={isLoading}
              >
                {'Cancel'}
              </Button>
            </div>
          </div>
        </ModalPaper>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.storage.isStorageModalOpen,
  isLoading: state.isLoading['INIT_STORAGE'],
  storageProvider: state.storage.storageProvider,
  successAction: state.storage.successAction,
  failureAction: state.storage.failureAction,
});

const mapDispatchToProps = (dispatch) => ({
  initStorage: ({
    storageProvider,
    storageToken,
    successAction,
    failureAction,
  }) =>
    dispatch(
      initStorageAsync.request({
        storageProvider,
        storageToken,
        successAction,
        failureAction,
      }),
    ),
  cancel: (failureAction) => {
    dispatch(closeStorageModal());
    if (failureAction) {
      dispatch(failureAction);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(StorageModal));
