import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MyRequestOrdersTable from './MyRequestOrdersTable';
import MyWorkerpoolOrdersTable from './MyWorkerpoolOrdersTable';
import MyTradesTable from './MyTradesTable';
import { Title, Modal, LoginHint } from './Table';
import { useWalletManager } from '@iexec/react-wallet-manager';

const styles = (theme) => ({
  title: {
    padding: '10px 20px',
    margin: '0px 5px 0px 0px',
    cursor: 'pointer',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `5px solid ${theme.palette.background.paper}`,
  },
  notSelected: {
    color: theme.palette.secondary.main,
    borderBottom: `5px solid ${theme.palette.background.default}`,
  },
  titles: {
    display: 'flex',
    flex: '0 0 auto',
    margin: '-10px -10px 15px -10px',
    backgroundColor: theme.palette.background.default,
  },
});

const MyBoard = ({ classes }) => {
  const [activeTab, setActiveTab] = useState(1);
  const { connected } = useWalletManager();
  return (
    <Fragment>
      <Modal open={!connected}>
        <LoginHint />
      </Modal>
      <div className={classes.titles}>
        <div onClick={() => setActiveTab(1)}>
          <Title
            className={classNames(
              classes.title,
              activeTab !== 1 && classes.notSelected,
            )}
          >
            My Trades
          </Title>
        </div>
        <div onClick={() => setActiveTab(2)}>
          <Title
            className={classNames(
              classes.title,
              activeTab !== 2 && classes.notSelected,
            )}
          >
            My Open Request Orders
          </Title>
        </div>
        <div onClick={() => setActiveTab(3)}>
          <Title
            className={classNames(
              classes.title,
              activeTab !== 3 && classes.notSelected,
            )}
          >
            My Open Workerpool Orders
          </Title>
        </div>
      </div>
      {activeTab === 1 && <MyTradesTable />}
      {activeTab === 2 && <MyRequestOrdersTable />}
      {activeTab === 3 && <MyWorkerpoolOrdersTable />}
    </Fragment>
  );
};

export default withStyles(styles)(MyBoard);
