import { combineReducers } from 'redux';
import filters from './dataFilters';
import { multiCriterionSort, timeSort } from '../../utils/filterAndSort';

function dapps(state = [], action) {
  switch (action.type) {
    case 'GET_DAPPS_SUCCESS':
      return [...action.dapps.filter(dapp => 'addresses' in dapp)];
    case 'GET_DAPPS_REQUEST':
      return [];
    default:
      return state;
  }
}

function datasets(state = [], action) {
  switch (action.type) {
    case 'GET_DATASETS_SUCCESS':
      return [...action.datasets.filter(dataset => 'addresses' in dataset)];
    case 'GET_DATASETS_REQUEST':
      return [];
    default:
      return state;
  }
}

function workerpoolOrders(state = [], action) {
  switch (action.type) {
    case 'CLEAR_TABLES':
      return [];
    case 'GET_WORKERPOOL_ORDERS_SUCCESS':
      return multiCriterionSort([
        {
          criteria: 'order.workerpoolprice',
          direction: 1,
        },
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...action.orders]);
    case 'ADD_WORKERPOOL_ORDER':
      return multiCriterionSort([
        {
          criteria: 'order.workerpoolprice',
          direction: 1,
        },
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...state, action.order]);
    case 'UPDATE_WORKERPOOL_ORDER':
      return state
        .map(order =>
          order.orderHash === action.order.orderHash ? action.order : order,
        )
        .filter(
          order => order.status === 'open' || order.status === 'cancelling',
        );
    case 'REMOVE_WORKERPOOL_ORDER':
      return state.filter(order => order.orderHash !== action.orderHash);
    default:
      return state;
  }
}

function requestOrders(state = [], action) {
  switch (action.type) {
    case 'CLEAR_TABLES':
      return [];
    case 'GET_REQUEST_ORDERS_SUCCESS':
      return multiCriterionSort([
        {
          criteria: 'order.workerpoolmaxprice',
          direction: -1,
        },
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...action.orders]);
    case 'ADD_REQUEST_ORDER':
      return multiCriterionSort([
        {
          criteria: 'order.workerpoolmaxprice',
          direction: -1,
        },
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...state, action.order]);
    case 'UPDATE_REQUEST_ORDER':
      return state
        .map(order =>
          order.orderHash === action.order.orderHash ? action.order : order,
        )
        .filter(order => order.status === 'open');
    case 'REMOVE_REQUEST_ORDER':
      return state.filter(order => order.orderHash !== action.orderHash);
    default:
      return state;
  }
}

function trades(state = [], action) {
  switch (action.type) {
    case 'CLEAR_TABLES':
      return [];
    case 'GET_TRADES_SUCCESS':
      return timeSort([...action.trades]);
    case 'ADD_TRADE':
      return [action.trade, ...state];
    default:
      return state;
  }
}

function myRequestOrders(state = [], action) {
  switch (action.type) {
    case 'CLEAR_TABLES':
    case 'CLEAR_USER_TABLES':
      return [];
    case 'GET_MY_REQUEST_ORDERS_SUCCESS':
      return multiCriterionSort([
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...action.orders]);
    case 'CANCEL_REQUEST_ORDER_SUCCESS':
      return [
        ...state.map(order =>
          order.orderHash === action.orderHash
            ? Object.assign(order, { status: 'cancelling' })
            : order,
        ),
      ];
    case 'ADD_MY_REQUEST_ORDER':
      return multiCriterionSort([
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...state, action.order]);
    case 'UPDATE_MY_REQUEST_ORDER':
      return state
        .map(order =>
          order.orderHash === action.order.orderHash ? action.order : order,
        )
        .filter(
          order => order.status === 'open' || order.status === 'cancelling',
        );
    case 'REMOVE_MY_REQUEST_ORDER':
      return state.filter(order => order.orderHash !== action.orderHash);
    default:
      return state;
  }
}

function myWorkerpoolOrders(state = [], action) {
  switch (action.type) {
    case 'CLEAR_TABLES':
    case 'CLEAR_USER_TABLES':
      return [];
    case 'GET_MY_WORKERPOOL_ORDERS_SUCCESS':
      return multiCriterionSort([
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...action.orders]);
    case 'CANCEL_WORKERPOOL_ORDER_SUCCESS':
      return [
        ...state.map(order =>
          order.orderHash === action.orderHash
            ? Object.assign(order, { status: 'cancelling' })
            : order,
        ),
      ];
    case 'ADD_MY_WORKERPOOL_ORDER':
      return multiCriterionSort([
        {
          criteria: 'publicationTimestamp',
          direction: 1,
        },
      ])([...state, action.order]);
    case 'UPDATE_MY_WORKERPOOL_ORDER':
      return state
        .map(order =>
          order.orderHash === action.order.orderHash ? action.order : order,
        )
        .filter(
          order => order.status === 'open' || order.status === 'cancelling',
        );
    case 'REMOVE_MY_WORKERPOOL_ORDER':
      return state.filter(order => order.orderHash !== action.orderHash);
    default:
      return state;
  }
}

function myTrades(state = [], action) {
  switch (action.type) {
    case 'CLEAR_TABLES':
    case 'CLEAR_USER_TABLES':
      return [];
    case 'GET_MY_TRADES_SUCCESS':
      return timeSort([...action.trades]);
    case 'ADD_MY_TRADE':
      return [
        action.trade,
        ...state.filter(trade => trade.dealid !== action.trade.dealid),
      ];
    default:
      return state;
  }
}

function ohlc(state = [], action) {
  switch (action.type) {
    case 'CLEAR_TABLES':
      return [];
    case 'GET_OHLC_SUCCESS':
      return [...action.ohlc];
    case 'OHLC_NEW_TRADE':
      const ohlcTrade = {
        blockTimestamp: new Date(action.trade.blockTimestamp),
        volume: action.trade.volume,
        value: action.trade.workerpool.price,
      };
      return [...state, ohlcTrade];
    default:
      return state;
  }
}

export default combineReducers({
  filters,
  dapps,
  datasets,
  workerpoolOrders,
  requestOrders,
  trades,
  myRequestOrders,
  myWorkerpoolOrders,
  myTrades,
  ohlc,
});
