import Debug from 'debug';
import { combineReducers } from 'redux';
import {
  validateAddress,
  validateNotNullInt,
  validateBytes32,
} from '../../utils/validation';

const debug = Debug('marketOrderSellForm');

function errors(state = {}, action) {
  if (
    action.type.includes('SET_MARKET_ORDER_SELL_FORM_') &&
    action.type.includes('_FIELD')
  ) {
    debug('action.type', action.type);
    const key = action.type
      .split('SET_MARKET_ORDER_SELL_FORM_')[1]
      .split('_FIELD')[0];
    debug('key', key);
    switch (action.type) {
      case 'SET_MARKET_ORDER_SELL_FORM_REQUESTORDER_HASH_FIELD':
        return { ...state, [key]: validateBytes32(action.value) };
      case 'SET_MARKET_ORDER_SELL_FORM_WORKERPOOL_ADDRESS_FIELD':
        return { ...state, [key]: validateAddress(action.value) };
      case 'SET_MARKET_ORDER_SELL_FORM_VOLUME_FIELD':
        return { ...state, [key]: validateNotNullInt(action.value) };
      default:
        return state;
    }
  }
  return state;
}

function requestOrderHash(state = '', action) {
  switch (action.type) {
    case 'SET_MARKET_ORDER_SELL_FORM_REQUESTORDER_HASH_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '';
    default:
      return state;
  }
}

function workerpoolAddress(state = '', action) {
  switch (action.type) {
    case 'SET_MARKET_ORDER_SELL_FORM_WORKERPOOL_ADDRESS_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '';
    default:
      return state;
  }
}

function volume(state = '1', action) {
  switch (action.type) {
    case 'SET_MARKET_ORDER_SELL_FORM_VOLUME_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '1';
    default:
      return state;
  }
}

function isConfirmModalOpen(state = false, action) {
  switch (action.type) {
    case 'TOGGLE_MARKET_SELL_CONFIRM_MODAL':
    case 'CONFIRM_SELL_MARKET_ORDER_SUCCESS':
      return !state;
    default:
      return state;
  }
}

function preparedDeal(state = {}, action) {
  switch (action.type) {
    case 'CONFIRM_SELL_MARKET_ORDER_SUCCESS':
      return {
        appOrder: action.appOrder,
        datasetOrder: action.datasetOrder,
        requestOrder: action.requestOrder,
        workerpoolOrderToSign: action.workerpoolOrderToSign,
      };
    case 'SELL_DEAL_REQUEST':
    case 'SELL_DEAL_SUCCESS':
    case 'SELL_DEAL_FAILURE':
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  errors,
  isConfirmModalOpen,
  requestOrderHash,
  workerpoolAddress,
  volume,
  preparedDeal,
});
