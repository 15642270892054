const compareTime = (a, b) => {
  if (a.blockTimestamp > b.blockTimestamp) {
    return -1;
  }
  return 1;
};

const compareMulticriterion = (critArray) => (a, b) => {
  let comp = 0;
  let i = 0;
  const getNestedCriteria = (criteria, obj) => {
    const critPath = criteria.split('.');
    let nested = obj;
    critPath.forEach((field) => {
      if (typeof nested === 'undefined') return undefined;
      nested = nested[field];
    });
    return nested;
  };
  const compare = (crit, direction, a, b) => {
    const nestedA = getNestedCriteria(crit, a);
    const nestedB = getNestedCriteria(crit, b);
    if (isNaN(Number(nestedA)) || isNaN(Number(nestedB))) {
      if (nestedA > nestedB) {
        return direction;
      }
      if (nestedA < nestedB) {
        return -1 * direction;
      }
    } else {
      if (Number(nestedA) > Number(nestedB)) {
        return direction;
      }
      if (Number(nestedA) < Number(nestedB)) {
        return -1 * direction;
      }
    }
    return 0;
  };
  while (comp === 0 && i < critArray.length) {
    const crit = critArray[i].criteria;
    const dir = critArray[i].direction || 1;
    comp = compare(crit, dir, a, b);
    i++;
  }
  return comp;
};

export const timeSort = (arr) => arr.sort(compareTime);

export const multiCriterionSort = (critArray) => (arr) =>
  arr.sort(compareMulticriterion(critArray));

export const findMostRecentIndex = (arr) =>
  arr.reduce(
    (accu, curr, index) => {
      const timestamp = curr.publicationTimestamp || curr.blockTimestamp;
      if (timestamp > accu.max)
        return {
          max: timestamp,
          index,
        };
      return accu;
    },
    { max: '', index: 0 },
  );
