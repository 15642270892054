import React, { Component } from 'react';
// import Debug from 'debug';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { ScaleLoader } from 'react-spinners';
import classNames from 'classnames';

// const debug = Debug('Table');

export const Title = withStyles((theme) => ({
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.primary.main,
    fontSize: '1.1em',
    fontWeight: 900,
    margin: '0px 0px 5px 0',
  },
}))(({ classes, className, children }) => {
  return <div className={classNames(classes.title, className)}>{children}</div>;
});

const modalStyles = (theme) => ({
  modal: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: theme.palette.background.paper,
  },
});
export const Modal = withStyles(modalStyles)(({ classes, children, open }) => {
  if (open) return <div className={classes.modal}>{children}</div>;
  return null;
});

export const LoginHint = withStyles((theme) => ({
  please: {
    fontSize: '1.5em',
  },
  login: {
    color: theme.palette.primary.main,
  },
}))(({ classes, children }) => {
  return (
    <div className={classNames(classes.please)}>
      Please <span className={classes.login}>Login</span> first
    </div>
  );
});

const styles = (theme) => ({
  tableGrid: {
    display: 'grid',
    overflow: 'auto',
  },
  tableBodyWrap: {
    overflow: 'auto',
  },
  headerGrid: {
    overflow: 'hidden',
    gridTemplateRows: 'auto 0px',
    height: '1.8em',
    minHeight: '1.8em',
  },
  cell: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '2px 10px',
  },
  sizerRow: {
    height: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  headerCell: {
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  row: {
    display: 'contents',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:hover a': {
      color: theme.palette.primary.main,
    },
  },
  priceUp: {
    animationName: 'priceUp',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes priceUp': {
    from: { backgroundColor: theme.palette.background.green },
    to: { backgroundColor: 'transparent' },
  },
  priceDown: {
    animationName: 'priceDown',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes priceDown': {
    from: { backgroundColor: theme.palette.background.red },
    to: { backgroundColor: 'transparent' },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    minHeight: 50,
    height: '100%',
  },
  flexHeigh: {
    flex: 1,
  },
  noScroll: {
    overflow: 'hidden',
  },
});

const splitProps = ({ className, style, ...rest }) => {
  return {
    className,
    style,
    rest: rest || {},
  };
};

const emptyObj = () => ({});
const defaultTableProps = {
  getRowProps: emptyObj,
  emptyContent: 'No Data',
};
const defaultColumnProps = {
  getCellProps: emptyObj,
};

class TableComponent extends Component {
  componentDidUpdate() {
    if (
      this.refs.tableBody &&
      this.props.noScroll &&
      this.props.reverseContent
    ) {
      this.refs.tableBody.scrollTop = this.refs.tableBody.scrollHeight;
    }
  }

  render() {
    const {
      classes,
      data,
      loading,
      columns,
      getRowProps,
      emptyContent,
      reverseContent,
      bottomHeader,
      noScroll,
      flexHeigh,
      theme,
    } = { ...defaultTableProps, ...this.props };
    const tableStyle = {
      gridTemplateColumns: `repeat(${columns.length}, auto)`,
    };
    let body;
    let sizerRow;
    let header;

    if (loading) {
      body = (
        <div className={classes.loader}>
          <ScaleLoader height={25} color={theme.palette.primary.main} />
        </div>
      );
    } else if (data.length > 0) {
      sizerRow = columns.map((column, index) => {
        const sizeByContent =
          column.headerMaxValue &&
          column.headerMaxValue(data).length > column.header.length;

        return (
          <div
            key={'sizer'.concat(index)}
            className={classNames(
              classes.cell,
              !sizeByContent && classes.headerCell,
              column.className,
              classes.sizerRow,
            )}
          >
            {sizeByContent ? column.headerMaxValue(data) : column.header}
          </div>
        );
      });
      body = (
        <div
          ref="tableBody"
          className={classNames(
            classes.tableBodyWrap,
            flexHeigh ? classes.flexHeigh : null,
            noScroll ? classes.noScroll : null,
          )}
        >
          <div className={classes.tableGrid} style={tableStyle}>
            {data.reduce((accu, curr, index) => {
              const rowProps = splitProps(getRowProps(curr, index));

              const cells = columns.map((column) => {
                const { getCellProps } = { ...defaultColumnProps, ...column };
                const cellProps = splitProps(getCellProps(curr, index));
                return (
                  <div
                    key={column.header.concat(index)}
                    className={classNames(
                      classes.cell,
                      column.className,
                      cellProps.className,
                    )}
                    {...cellProps.rest}
                  >
                    {column.cell(curr)}
                  </div>
                );
              });
              const row = [
                <div
                  key={'row'.concat(index)}
                  className={classNames(classes.row, rowProps.className)}
                  {...rowProps.rest}
                >
                  {cells}
                </div>,
              ];
              if (reverseContent) {
                return row.concat(accu);
              } else {
                return accu.concat(row);
              }
            }, [])}
            <div className={classNames(classes.row)}>{sizerRow}</div>
          </div>
        </div>
      );
    } else {
      body = <div className={classes.loader}>{emptyContent}</div>;
    }
    header = (
      <div
        className={classNames(classes.tableGrid, classes.headerGrid)}
        style={tableStyle}
      >
        <div className={classNames(classes.row)}>
          {columns.map((column, index) => (
            <div
              key={'header'.concat(index)}
              className={classNames(
                classes.cell,
                classes.headerCell,
                column.headerClassName,
              )}
            >
              {column.header}
            </div>
          ))}
        </div>
        <div className={classNames(classes.row)}>{sizerRow}</div>
      </div>
    );

    return (
      <>
        {!bottomHeader && header}
        {body}
        {bottomHeader && header}
      </>
    );
  }
}

export const Table = withStyles(styles)(withTheme(TableComponent));
