import Debug from 'debug';
import {
  all,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
  call,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import IExecConfig from 'iexec/IExecConfig';
import { jsonApi } from '../../services/api';
import { getWebsocket } from '../../services/websocket';
import * as actions from '../actions/data';
import { validateTagRequirement } from '../../utils/iexecOrders';
import { sleep } from '../../utils/utils';
import * as notifierActions from '../actions/notifier';
import { getApiUrl } from '../../utils/chain';
import { multiWeb3 } from '../../services/web3';
import { PROVIDER_ACCOUNT_CHANGED, SET_CURRENT_CHAIN_ID } from '../types/chain';

const debug = Debug('sagas:data');

export function* refreshData() {
  debug('refreshData()');
  yield put(actions.getDappsAsync.request());
  yield put(actions.getDatasetsAsync.request());
  yield refreshPublicData();
  yield refreshPrivateData();
}

function* refreshPrivateData() {
  debug('refreshPrivateData()');
  yield put(actions.clearUserTables());
  yield put(actions.getMyOrdersAsync.request());
  yield put(actions.getMyTradesAsync.request());
}

function* refreshPublicData() {
  debug('refreshPublicData()');
  yield put(actions.clearTables());
  yield put(actions.getOrdersAsync.request());
  yield put(actions.getTradesAsync.request());
  yield put(actions.getOHLCAsync.request());
  yield put(actions.subscribeWS());
}

function createDataChannel(url, topics, forwardMessages) {
  return eventChannel((emitter) => {
    const ws = getWebsocket(url, { path: '/ws' });
    topics.forEach((topic) => {
      debug('joining', topic);
      ws.emit('join', topic, (joinACK) => debug('join', topic, joinACK));
    });
    forwardMessages.forEach((type) => {
      ws.on(type, (message) => {
        emitter({ type, data: message });
      });
    });
    return () => {
      debug('createDataChannel closing ws');
      ws.disconnect();
    };
  });
}

function* subscribeWS() {
  try {
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    debug('subscribeWS() chainId', chainId);
    const topics = [
      {
        chainId,
        topic: 'orders',
      },
      {
        chainId,
        topic: 'deals',
      },
    ];
    const handlersMapping = {
      workerpoolorder_published: function* (data) {
        yield put(actions.wsEvents.workerpoolOrderPlaced(data));
      },
      workerpoolorder_unpublished: function* (data) {
        yield put(actions.wsEvents.workerpoolOrderUnpublished(data));
      },
      workerpoolorder_updated: function* (data) {
        yield put(actions.wsEvents.workerpoolOrderUpdated(data));
      },
      requestorder_published: function* (data) {
        yield put(actions.wsEvents.requestOrderPlaced(data));
      },
      requestorder_unpublished: function* (data) {
        yield put(actions.wsEvents.requestOrderUnpublished(data));
      },
      requestorder_updated: function* (data) {
        yield put(actions.wsEvents.requestOrderUpdated(data));
      },
      deal_created: function* (data) {
        yield put(actions.wsEvents.newTrade(data));
      },
      error: function (data) {
        debug('error', data);
      },
    };
    const forwardMessages = Object.keys(handlersMapping);

    const channel = createDataChannel(api, topics, forwardMessages);
    try {
      while (true) {
        const { type, data } = yield take(channel);
        if (handlersMapping[type]) {
          yield handlersMapping[type](data);
        } else {
          debug(`no handler for ${type}`);
        }
      }
    } finally {
      debug('subscribeWS() finally');
      channel.close();
    }
  } catch (error) {
    debug('subscribeWS', error);
  }
}

export function* getWorkerpoolOrders() {
  try {
    debug('getWorkerpoolOrders()');
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    const category = yield select((state) => state.data.filters.category);
    const tag = yield select((state) => state.data.filters.tag);
    const trust = yield select((state) => state.data.filters.trust);
    const minTrust =
      trust.error || trust.value === '' ? undefined : parseInt(trust.value);
    const res = yield jsonApi.get({
      api,
      endpoint: '/workerpoolorders',
      query: {
        chainId,
        category,
        minTag: tag,
        minTrust,
      },
    });
    debug('getWorkerpoolOrders()', res);
    yield put(actions.getWorkerpoolOrdersAsync.success(res.orders));
  } catch (error) {
    debug('getWorkerpoolOrders()', error);
    yield put(actions.getWorkerpoolOrdersAsync.failure(error.message));
  }
}

export function* getRequestOrders() {
  try {
    debug('getRequestOrders()');
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    const category = yield select((state) => state.data.filters.category);
    const tag = yield select((state) => state.data.filters.tag);
    const trust = yield select((state) => state.data.filters.trust);
    const maxTrust =
      trust.error || trust.value === '' ? undefined : parseInt(trust.value);
    const res = yield jsonApi.get({
      api,
      endpoint: '/requestorders',
      query: {
        chainId,
        category,
        maxTag: tag,
        maxTrust,
      },
    });
    debug('getRequestOrders()', res);
    yield put(actions.getRequestOrdersAsync.success(res.orders));
  } catch (error) {
    debug('getRequestOrders()', error);
    yield put(actions.getRequestOrdersAsync.failure(error.message));
  }
}

export function* getOrders() {
  try {
    debug('getOrders()');
    yield all([getWorkerpoolOrders(), getRequestOrders()]);
    yield put(actions.getOrdersAsync.success());
  } catch (error) {
    debug('getOrders()', error);
    yield put(actions.getOrdersAsync.failure(error.message));
  }
}

export function* refreshOrderbook() {
  yield sleep(500);
  const trust = yield select((state) => state.data.filters.trust);
  if (trust.error) return;
  yield put(actions.getOrdersAsync.request());
}

export function* getTrades() {
  try {
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    const category = yield select((state) => state.data.filters.category);
    const query = {
      chainId,
      category,
    };
    const res = yield jsonApi.get({
      api,
      endpoint: '/deals',
      query,
    });
    debug('getTrades()', res);
    yield put(actions.getTradesAsync.success(res.deals));
  } catch (error) {
    debug('getTrades()', error);
    yield put(actions.getTradesAsync.failure(error.message));
  }
}

export function* getMyOrders() {
  try {
    yield all([getMyRequestOrders(), getMyWorkerpoolOrders()]);
    yield put(actions.getMyOrdersAsync.success());
  } catch (error) {
    debug('getMyOrders()', error);
    yield put(actions.getMyOrdersAsync.failure(error.message));
  }
}

export function* getMyRequestOrders() {
  try {
    const { account: address } = multiWeb3.getStatus();
    if (!address) {
      return yield put(actions.getMyRequestOrdersAsync.success([]));
    }
    debug('getMyRequestOrders() address', address);
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    const category = yield select((state) => state.data.filters.category);
    const res = yield jsonApi.get({
      api,
      endpoint: '/requestorders',
      query: {
        chainId,
        category,
        requester: address,
      },
    });
    debug('getMyRequestOrders()', res);
    yield put(actions.getMyRequestOrdersAsync.success(res.orders));
  } catch (error) {
    debug('getMyRequestOrders()', error);
    yield put(actions.getMyRequestOrdersAsync.failure(error.message));
  }
}

export function* getMyWorkerpoolOrders() {
  try {
    const { account: address } = multiWeb3.getStatus();
    if (!address) {
      return yield put(actions.getMyWorkerpoolOrdersAsync.success([]));
    }
    debug('getMyWorkerpoolOrders() address', address);
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    const category = yield select((state) => state.data.filters.category);
    const res = yield jsonApi.get({
      api,
      endpoint: '/workerpoolorders',
      query: {
        chainId,
        category,
        workerpoolOwner: address,
      },
    });
    debug('getMyWorkerpoolOrders()', res);
    yield put(actions.getMyWorkerpoolOrdersAsync.success(res.orders));
  } catch (error) {
    debug('getMyWorkerpoolOrders()', error);
    yield put(actions.getMyWorkerpoolOrdersAsync.failure(error.message));
  }
}

export function* getMyTrades() {
  try {
    const { account: address } = multiWeb3.getStatus();
    if (!address) {
      return yield put(actions.getMyTradesAsync.success([]));
    }
    debug('getMyTrades() address', address);
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    const category = yield select((state) => state.data.filters.category);
    const query = {
      chainId,
      category,
      requester: address,
    };
    const res = yield jsonApi.get({
      api,
      endpoint: '/deals',
      query,
    });
    debug('getMyTrades()', res);
    yield put(actions.getMyTradesAsync.success(res.deals));
  } catch (error) {
    debug('getMyTrades()', error);
    yield put(actions.getMyTradesAsync.failure(error.message));
  }
}

export function* getOHLC() {
  try {
    const chainId = yield select((state) => state.chain.chainId);
    const config = new IExecConfig({ ethProvider: chainId });
    const api = yield call(config.resolveIexecGatewayURL);
    const category = yield select((state) => state.data.filters.category);
    const query = { chainId, category };
    const res = yield jsonApi.get({
      api,
      endpoint: '/ohlc',
      query,
    });
    debug('getOHLC()', res);

    const ohlc = res.ohlc
      .map((e) => ({
        blockTimestamp: new Date(e[0]),
        value: e[1],
        volume: e[2],
      }))
      .reverse();
    yield put(actions.getOHLCAsync.success(ohlc));
  } catch (error) {
    debug('getOHLC()', error);
    yield put(actions.getOHLCAsync.failure(error.message));
  }
}

export function* getDapps({ query }) {
  try {
    debug('getDapps() query', query);
    const api = getApiUrl();
    const data = yield jsonApi.post({
      api,
      endpoint: '/dapps',
    });
    yield put(actions.getDappsAsync.success(data.dapps));
  } catch (error) {
    yield put(actions.getDappsAsync.failure(error.message));
  }
}

export function* getDatasets({ query }) {
  try {
    debug('getDatasets() query', query);
    const api = getApiUrl();
    const data = yield jsonApi.post({
      api,
      endpoint: '/data',
    });
    yield put(actions.getDatasetsAsync.success(data.data));
  } catch (error) {
    yield put(actions.getDatasetsAsync.failure(error.message));
  }
}

function* updateTrades(action) {
  try {
    debug('updateTrades()', action);
    const category = yield select((state) => state.data.filters.category);
    const { trade } = action;
    if (trade.category !== category) return;
    //update trades
    debug('updateTrades()', 'addTrade', trade);
    yield put(actions.addTrade(trade));
    yield put(actions.addOhlc(trade));
    //update my trades
    const { account: address } = multiWeb3.getStatus();
    if (address) {
      const isSeller =
        trade.workerpool.owner.toLowerCase() === address.toLowerCase();
      const isBuyer = trade.requester.toLowerCase() === address.toLowerCase();
      if (!isSeller && !isBuyer) return;
      if (isSeller)
        yield put(
          notifierActions.notify({
            message: `✓ New trade! Your workerpool order has been filled`,
            level: 'success',
          }),
        );
      if (isBuyer)
        yield put(
          notifierActions.notify({
            message: `✓ New trade! Your request order has been filled`,
            level: 'success',
          }),
        );
      debug('updateTrades()', 'addMyTrade', trade);
      yield put(actions.addMyTrade(trade));
    }
  } catch (error) {
    debug('updateTrades()', error);
  }
}

function* processRequestOrderPublished(action) {
  yield all([addRequestOrder(action), addMyRequestOrder(action)]);
}

function* addRequestOrder(action) {
  try {
    debug('addRequestOrder()', action);
    const { order } = action;
    // check filters
    const category = yield select((state) => state.data.filters.category);
    if (order.order.category !== category) {
      debug('addRequestOrder category mismatch');
      return;
    }
    const trust = yield select((state) => state.data.filters.trust);
    const maxTrust =
      trust.error || trust.value === '' ? undefined : parseInt(trust.value);
    if (maxTrust !== undefined && parseInt(order.order.trust) > maxTrust) {
      debug('addRequestOrder trust mismatch');
      return;
    }
    const maxTag = yield select((state) => state.data.filters.tag);
    const tagError = validateTagRequirement(maxTag, [order.order.tag]);
    if (tagError) {
      debug('addRequestOrder tag mismatch');
      return;
    }
    //update orders
    debug('addRequestOrder()', 'addRequestOrder', order);
    yield put(actions.addRequestOrder(order));
  } catch (error) {
    debug('addRequestOrder()', error);
  }
}

function* addMyRequestOrder(action) {
  try {
    debug('addMyRequestOrder()', action);
    const { order } = action;
    const { account: address } = multiWeb3.getStatus();
    if (address) {
      // check filters
      const category = yield select((state) => state.data.filters.category);
      if (order.order.category !== category) {
        debug('addMyRequestOrder category mismatch');
        return;
      }
      if (order.signer.toLowerCase() !== address.toLowerCase()) {
        debug('addMyRequestOrder requester mismatch');
        return;
      }
      debug('addMyRequestOrder()', 'addMyRequestOrder', order);
      yield put(actions.addMyRequestOrder(order));
    }
  } catch (error) {
    debug('addMyRequestOrder()', error);
  }
}

function* removeRequestOrder(action) {
  try {
    debug('removeRequestOrder()', action);
    const { orderHash } = action;
    //update orders
    debug('removeRequestOrder()', 'removeRequestOrder', orderHash);
    yield put(actions.removeRequestOrder(orderHash));
    //update my orders
    const { connected } = multiWeb3.getStatus();
    if (connected) {
      debug('removeRequestOrder()', 'removeMyRequestOrder', orderHash);
      yield put(actions.removeMyRequestOrder(orderHash));
    }
  } catch (error) {
    debug('removeRequestOrder()', error);
  }
}

function* updateRequestOrder(action) {
  try {
    debug('updateRequestOrder()', action);
    const category = yield select((state) => state.data.filters.category);
    const { order } = action;
    if (order.order.category !== category) return;
    //update orders
    debug('updateRequestOrder()', 'updateRequestOrder', order);
    yield put(actions.updateRequestOrder(order));
    //update my orders
    const { account: address } = multiWeb3.getStatus();
    if (address) {
      if (order.signer.toLowerCase() !== address.toLowerCase()) return;
      debug('updateRequestOrder()', 'updateMyRequestOrder', order);
      yield put(actions.updateMyRequestOrder(order));
    }
  } catch (error) {
    debug('updateRequestOrder()', error);
  }
}

function* processWorkerpoolOrderPublished(action) {
  yield all([addWorkerpoolOrder(action), addMyWorkerpoolOrder(action)]);
}

function* addWorkerpoolOrder(action) {
  try {
    debug('addWorkerpoolOrder()', action);
    const { order } = action;
    // check filters
    const category = yield select((state) => state.data.filters.category);
    if (order.order.category !== category) {
      debug('addWorkerpoolOrder category mismatch');
      return;
    }
    const trust = yield select((state) => state.data.filters.trust);
    const minTrust =
      trust.error || trust.value === '' ? undefined : parseInt(trust.value);
    if (minTrust !== undefined && parseInt(order.order.trust) < minTrust) {
      debug('addWorkerpoolOrder trust mismatch');
      return;
    }
    const minTag = yield select((state) => state.data.filters.tag);
    const tagError = validateTagRequirement(order.order.tag, [minTag]);
    if (tagError) {
      debug('addWorkerpoolOrder tag mismatch');
      return;
    }
    //update orders
    debug('addWorkerpoolOrder()', 'addWorkerpoolOrder', order);
    yield put(actions.addWorkerpoolOrder(order));
  } catch (error) {
    debug('addWorkerpoolOrder()', error);
  }
}

function* addMyWorkerpoolOrder(action) {
  try {
    debug('addMyWorkerpoolOrder()', action);
    const { order } = action;
    const { account: address } = multiWeb3.getStatus();
    if (address) {
      // check filters
      if (order.signer.toLowerCase() !== address.toLowerCase()) {
        debug('addMyWorkerpoolOrder workerpool owner mismatch');
        return;
      }
      const category = yield select((state) => state.data.filters.category);
      if (order.order.category !== category) {
        debug('addMyWorkerpoolOrder category mismatch');
        return;
      }
      debug('addMyWorkerpoolOrder()', 'addMyWorkerpoolOrder', order);
      yield put(actions.addMyWorkerpoolOrder(order));
    }
  } catch (error) {
    debug('addMyWorkerpoolOrder()', error);
  }
}

function* removeWorkerpoolOrder(action) {
  try {
    debug('removeWorkerpoolOrder()', action);
    const { orderHash } = action;
    //update orders
    debug('removeWorkerpoolOrder()', 'removeWorkerpoolOrder', orderHash);
    yield put(actions.removeWorkerpoolOrder(orderHash));
    //update my orders
    const { connected } = multiWeb3.getStatus();
    if (connected) {
      debug('removeWorkerpoolOrder()', 'removeMyWorkerpoolOrder', orderHash);
      yield put(actions.removeMyWorkerpoolOrder(orderHash));
    }
  } catch (error) {
    debug('removeWorkerpoolOrder()', error);
  }
}

function* updateWorkerpoolOrder(action) {
  try {
    debug('updateWorkerpoolOrder()', action);
    const category = yield select((state) => state.data.filters.category);
    const { order } = action;
    if (order.order.category !== category) return;
    //update orders
    debug('updateWorkerpoolOrder()', 'updateWorkerpoolOrder', order);
    yield put(actions.updateWorkerpoolOrder(order));
    //update my orders
    const { account: address } = multiWeb3.getStatus();
    if (address) {
      if (order.signer.toLowerCase() !== address.toLowerCase()) return;
      debug('updateWorkerpoolOrder()', 'updateMyWorkerpoolOrder', order);
      yield put(actions.updateMyWorkerpoolOrder(order));
    }
  } catch (error) {
    debug('updateWorkerpoolOrder()', error);
  }
}

// SAGAS WATCHERS
export function* watchData() {
  yield takeLatest('GET_DAPPS_REQUEST', getDapps);
  yield takeLatest('GET_DATASETS_REQUEST', getDatasets);
  yield takeLatest('GET_OHLC_REQUEST', getOHLC);
  yield takeLatest('GET_TRADES_REQUEST', getTrades);
  yield takeLatest('GET_ORDERS_REQUEST', getOrders);
  yield takeLatest('GET_WORKERPOOL_ORDERS_REQUEST', getWorkerpoolOrders);
  yield takeLatest('GET_REQUEST_ORDERS_REQUEST', getRequestOrders);
  yield takeLatest('GET_MY_TRADES_REQUEST', getMyTrades);
  yield takeLatest('GET_MY_ORDERS_REQUEST', getMyOrders);
  yield takeLatest('GET_MY_WORKERPOOL_ORDERS_REQUEST', getMyWorkerpoolOrders);
  yield takeLatest('GET_MY_REQUEST_ORDERS_REQUEST', getMyRequestOrders);
  yield takeLatest('SUBSCRIBE_WS', subscribeWS);
  // init
  yield takeLatest('STARTUP', refreshData);
  // chain changed trigger
  yield takeLatest(SET_CURRENT_CHAIN_ID, refreshData);
  // web3Provider trigger
  yield takeLatest(PROVIDER_ACCOUNT_CHANGED, refreshPrivateData);
  // filters trigger
  yield takeLatest('SET_CATEGORY', refreshData);
  yield takeLatest('SET_TAG', refreshOrderbook);
  yield takeLatest('SET_TRUST', refreshOrderbook);
  // ws trigger
  yield takeEvery('WS_REQUEST_ORDER_PLACED', processRequestOrderPublished);
  yield takeEvery('WS_REQUEST_ORDER_UNPUBLISHED', removeRequestOrder);
  yield takeEvery('WS_REQUEST_ORDER_UPDATED', updateRequestOrder);
  yield takeEvery(
    'WS_WORKERPOOL_ORDER_PLACED',
    processWorkerpoolOrderPublished,
  );
  yield takeEvery('WS_WORKERPOOL_ORDER_UNPUBLISHED', removeWorkerpoolOrder);
  yield takeEvery('WS_WORKERPOOL_ORDER_UPDATED', updateWorkerpoolOrder);
  yield takeEvery('WS_NEW_TRADE', updateTrades);
}

export default function* dataSaga() {
  yield all([watchData()]);
}
