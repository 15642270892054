import { combineReducers } from 'redux';

function isStorageModalOpen(state = false, action) {
  switch (action.type) {
    case 'CLOSE_STORAGE_MODAL':
      return false;
    case 'OPEN_STORAGE_MODAL':
      return true;
    default:
      return state;
  }
}

function storageProvider(state = '', action) {
  switch (action.type) {
    case 'CLOSE_STORAGE_MODAL':
      return '';
    case 'OPEN_STORAGE_MODAL':
      return action.storageProvider;
    default:
      return state;
  }
}

function successAction(state = null, action) {
  switch (action.type) {
    case 'CLOSE_STORAGE_MODAL':
      return null;
    case 'OPEN_STORAGE_MODAL':
      return action.successAction;
    default:
      return state;
  }
}

function failureAction(state = null, action) {
  switch (action.type) {
    case 'CLOSE_STORAGE_MODAL':
      return null;
    case 'OPEN_STORAGE_MODAL':
      return action.failureAction;
    default:
      return state;
  }
}

export default combineReducers({
  isStorageModalOpen,
  storageProvider,
  successAction,
  failureAction,
});
