export const openStorageModal = ({
  storageProvider,
  successAction,
  failureAction,
}) => ({
  type: 'OPEN_STORAGE_MODAL',
  storageProvider,
  successAction,
  failureAction,
});

export const closeStorageModal = () => ({
  type: 'CLOSE_STORAGE_MODAL',
});
