const handleFormChange = formName => inputName => value => ({
  type: `SET_${formName}_FORM_${inputName}_FIELD`,
  value,
});
export const handleMarketOrderBuyFormChange = handleFormChange(
  'MARKET_ORDER_BUY',
);
export const handleMarketOrderSellFormChange = handleFormChange(
  'MARKET_ORDER_SELL',
);
export const handleLimitOrderBuyFormChange = handleFormChange(
  'LIMIT_ORDER_BUY',
);
export const handleLimitOrderSellFormChange = handleFormChange(
  'LIMIT_ORDER_SELL',
);

export const clearOrderForm = () => ({ type: 'CLEAR_ORDER_FORM' });

export const toggleMarketBuyConfirmModal = () => ({
  type: 'TOGGLE_MARKET_BUY_CONFIRM_MODAL',
});

export const toggleMarketSellConfirmModal = () => ({
  type: 'TOGGLE_MARKET_SELL_CONFIRM_MODAL',
});
