export const getOrdersAsync = {
  request: (query) => ({ type: 'GET_ORDERS_REQUEST', query }),
  success: () => ({ type: 'GET_ORDERS_SUCCESS' }),
  failure: (error) => ({ type: 'GET_ORDERS_FAILURE', error }),
};

export const getWorkerpoolOrdersAsync = {
  request: (query) => ({ type: 'GET_WORKERPOOL_ORDERS_REQUEST', query }),
  success: (orders) => ({ type: 'GET_WORKERPOOL_ORDERS_SUCCESS', orders }),
  failure: (error) => ({ type: 'GET_WORKERPOOL_ORDERS_FAILURE', error }),
};

export const getRequestOrdersAsync = {
  request: (query) => ({ type: 'GET_REQUEST_ORDERS_REQUEST', query }),
  success: (orders) => ({ type: 'GET_REQUEST_ORDERS_SUCCESS', orders }),
  failure: (error) => ({ type: 'GET_REQUEST_ORDERS_FAILURE', error }),
};

export const getTradesAsync = {
  request: (query) => ({ type: 'GET_TRADES_REQUEST', query }),
  success: (trades) => ({ type: 'GET_TRADES_SUCCESS', trades }),
  failure: (error) => ({ type: 'GET_TRADES_FAILURE', error }),
};

export const getOHLCAsync = {
  request: (query) => ({ type: 'GET_OHLC_REQUEST', query }),
  success: (ohlc) => ({ type: 'GET_OHLC_SUCCESS', ohlc }),
  failure: (error) => ({ type: 'GET_OHLC_FAILURE', error }),
};

export const getDappsAsync = {
  request: (query) => ({ type: 'GET_DAPPS_REQUEST', query }),
  success: (dapps) => ({ type: 'GET_DAPPS_SUCCESS', dapps }),
  failure: (error) => ({ type: 'GET_DAPPS_FAILURE', error }),
};

export const getDatasetsAsync = {
  request: (query) => ({ type: 'GET_DATASETS_REQUEST', query }),
  success: (datasets) => ({ type: 'GET_DATASETS_SUCCESS', datasets }),
  failure: (error) => ({ type: 'GET_DATASETS_FAILURE', error }),
};

export const getMyOrdersAsync = {
  request: (query) => ({ type: 'GET_MY_ORDERS_REQUEST', query }),
  success: () => ({ type: 'GET_MY_ORDERS_SUCCESS' }),
  failure: (error) => ({ type: 'GET_MY_ORDERS_FAILURE', error }),
};

export const getMyRequestOrdersAsync = {
  request: (query) => ({ type: 'GET_MY_REQUEST_ORDERS_REQUEST', query }),
  success: (orders) => ({ type: 'GET_MY_REQUEST_ORDERS_SUCCESS', orders }),
  failure: (error) => ({ type: 'GET_MY_REQUEST_ORDERS_FAILURE', error }),
};

export const getMyWorkerpoolOrdersAsync = {
  request: (query) => ({ type: 'GET_MY_WORKERPOOL_ORDERS_REQUEST', query }),
  success: (orders) => ({ type: 'GET_MY_WORKERPOOL_ORDERS_SUCCESS', orders }),
  failure: (error) => ({ type: 'GET_MY_WORKERPOOL_ORDERS_FAILURE', error }),
};

export const getMyTradesAsync = {
  request: (query) => ({ type: 'GET_MY_TRADES_REQUEST', query }),
  success: (trades) => ({ type: 'GET_MY_TRADES_SUCCESS', trades }),
  failure: (error) => ({ type: 'GET_MY_TRADES_FAILURE', error }),
};

export const subscribeWS = () => ({ type: 'SUBSCRIBE_WS' });

export const clearTables = () => ({ type: 'CLEAR_TABLES' });

export const clearUserTables = () => ({ type: 'CLEAR_USER_TABLES' });

export const addTrade = (trade) => ({ type: 'ADD_TRADE', trade });

export const addMyTrade = (trade) => ({ type: 'ADD_MY_TRADE', trade });

export const addOhlc = (trade) => ({ type: 'OHLC_NEW_TRADE', trade });

export const addRequestOrder = (order) => ({
  type: 'ADD_REQUEST_ORDER',
  order,
});

export const removeRequestOrder = (orderHash) => ({
  type: 'REMOVE_REQUEST_ORDER',
  orderHash,
});
export const updateRequestOrder = (order) => ({
  type: 'UPDATE_REQUEST_ORDER',
  order,
});
export const addMyRequestOrder = (order) => ({
  type: 'ADD_MY_REQUEST_ORDER',
  order,
});
export const removeMyRequestOrder = (orderHash) => ({
  type: 'REMOVE_MY_REQUEST_ORDER',
  orderHash,
});
export const updateMyRequestOrder = (order) => ({
  type: 'UPDATE_MY_REQUEST_ORDER',
  order,
});
export const addWorkerpoolOrder = (order) => ({
  type: 'ADD_WORKERPOOL_ORDER',
  order,
});
export const removeWorkerpoolOrder = (orderHash) => ({
  type: 'REMOVE_WORKERPOOL_ORDER',
  orderHash,
});
export const updateWorkerpoolOrder = (order) => ({
  type: 'UPDATE_WORKERPOOL_ORDER',
  order,
});
export const addMyWorkerpoolOrder = (order) => ({
  type: 'ADD_MY_WORKERPOOL_ORDER',
  order,
});
export const removeMyWorkerpoolOrder = (orderHash) => ({
  type: 'REMOVE_MY_WORKERPOOL_ORDER',
  orderHash,
});
export const updateMyWorkerpoolOrder = (order) => ({
  type: 'UPDATE_MY_WORKERPOOL_ORDER',
  order,
});

export const wsEvents = {
  connect: () => ({ type: 'WS_EVENTS_CONNECT' }),
  disconnect: () => ({ type: 'WS_EVENTS_DISCONNECT' }),
  workerpoolOrderPlaced: (order) => ({
    type: 'WS_WORKERPOOL_ORDER_PLACED',
    order,
  }),
  workerpoolOrderUpdated: (order) => ({
    type: 'WS_WORKERPOOL_ORDER_UPDATED',
    order,
  }),
  workerpoolOrderUnpublished: (orderHash) => ({
    type: 'WS_WORKERPOOL_ORDER_UNPUBLISHED',
    orderHash,
  }),
  requestOrderPlaced: (order) => ({ type: 'WS_REQUEST_ORDER_PLACED', order }),
  requestOrderUpdated: (order) => ({
    type: 'WS_REQUEST_ORDER_UPDATED',
    order,
  }),
  requestOrderUnpublished: (orderHash) => ({
    type: 'WS_REQUEST_ORDER_UNPUBLISHED',
    orderHash,
  }),
  newTrade: (trade) => ({
    type: 'WS_NEW_TRADE',
    trade,
  }),
};
