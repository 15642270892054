import React, { Component } from 'react';
// import Debug from 'debug';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaBars } from 'react-icons/fa';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { startup, set } from '../store/actions/app';
import { handleMarketOrderBuyFormChange } from '../store/actions/form';
import navBarLogo from '../assets/images/iexec-logo.svg';
import Login from './Login';
import {
  validateInt,
  validateNotNullInt,
  validateAddress,
  validateBytes32,
} from '../utils/validation';
import MarketBuyConfirmModal from './MarketBuyConfirmModal';
import MarketSellConfirmModal from './MarketSellConfirmModal';
import StorageModal from './StorageModal';

// const debug = Debug('NavBar');

const styles = (theme) => ({
  root: {
    'grid-area': 'header',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftBox: {
    display: 'flex',
  },
  logoBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '1em',
  },
  logo: {
    height: 'auto',
    width: '6em',
  },
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    margin: '0px 10px',
    color: theme.palette.text.primary,
  },
  rightBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bars: {
    cursor: 'pointer',
    fontSize: '1.5em',
    color: theme.palette.text.primary,
    padding: 20,
    lineHeight: 0,
  },
  loginText: {
    fontSize: '1.3em',
  },
});

class NavBar extends Component {
  componentDidMount() {
    const {
      location,
      startup,
      setCategory,
      setDappAddress,
      setDatasetAddress,
      setTrust,
      setTag,
      setParamsArgs,
      setCallback,
    } = this.props;
    startup();
    const { category, dapp, dataset, iexec_args, tag, trust, callback } =
      queryString.parse(location.search);
    if (category) setCategory(category);
    if (dapp) setDappAddress(dapp);
    if (dataset) setDatasetAddress(dataset);
    if (tag) setTag(tag);
    if (trust) setTrust(trust);
    if (iexec_args) setParamsArgs(iexec_args);
    if (callback) setCallback(callback);
  }
  render() {
    const {
      classes,
      toggleDrawer,
      isMarketBuyConfirmModalOpen,
      isMarketSellConfirmModalOpen,
      isStorageModalOpen,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.leftBox}>
          <Link className={classes.logoBox} to={'/'}>
            <img className={classes.logo} src={navBarLogo} alt="iExec logo" />{' '}
            <div className={classes.title}> Marketplace </div>
          </Link>
        </div>
        <div className={classes.rightBox}>
          {isMarketBuyConfirmModalOpen && <MarketBuyConfirmModal />}
          {isMarketSellConfirmModalOpen && <MarketSellConfirmModal />}
          {isStorageModalOpen && <StorageModal />}
          <Login>
            <div className={classes.loginText}>Login</div>
          </Login>
          <div
            className={classes.bars}
            onClick={() => toggleDrawer()}
            onKeyDown={() => toggleDrawer()}
          >
            <FaBars />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isMarketBuyConfirmModalOpen: state.marketOrderBuyForm.isConfirmModalOpen,
  isMarketSellConfirmModalOpen: state.marketOrderSellForm.isConfirmModalOpen,
  isStorageModalOpen: state.storage.isStorageModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(startup()),
  toggleDrawer: () => dispatch(set.toggleDrawer()),
  setCategory: (category) => {
    validateNotNullInt(category);
    dispatch(set.category(parseInt(category)));
  },
  setDappAddress: (dappAddress) => {
    const inputError = validateAddress(dappAddress);
    if (!inputError)
      dispatch(
        handleMarketOrderBuyFormChange('DAPP_ADDRESS')({
          value: dappAddress,
          label: dappAddress,
        }),
      );
  },
  setDatasetAddress: (datasetAddress) => {
    const inputError = validateAddress(datasetAddress);
    if (!inputError)
      dispatch(
        handleMarketOrderBuyFormChange('DATASET_ADDRESS')({
          value: datasetAddress,
          label: datasetAddress,
        }),
      );
  },
  setParamsArgs: (paramsArgs) => {
    dispatch(handleMarketOrderBuyFormChange('PARAMS_ARGS')(paramsArgs));
  },
  setCallback: (callback) => {
    const inputError = validateAddress(callback);
    if (!inputError)
      dispatch(handleMarketOrderBuyFormChange('CALLBACK')(callback));
  },
  setTrust: (trust) => {
    const inputError = validateInt(trust);
    if (!inputError) {
      dispatch(handleMarketOrderBuyFormChange('TRUST')(trust));
      dispatch(set.trust(trust));
    }
  },
  setTag: (tag) => {
    const inputError = validateBytes32(tag);
    if (!inputError) {
      dispatch(handleMarketOrderBuyFormChange('TAG')(tag));
      dispatch(set.tag(tag));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NavBar));
