import React, { Component } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Table } from './Table';
import RlcDisplay from './RlcDisplay';
import { findMostRecentIndex } from '../utils/filterAndSort';
import { getChainKey } from '../utils/chain';
import { NULL_ADDRESS } from '../utils/ethereum';
import { EXPLORER_BASE_URL } from '../config';

const debug = Debug('MyTradesTable');
debug('');

const styles = (theme) => ({
  filling: {
    animationName: 'filling',
    animationDuration: '3s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  '@keyframes filling': {
    '0%': { backgroundColor: theme.palette.background.main() },
    '50%': { backgroundColor: 'transparent' },
    '100%': { backgroundColor: theme.palette.background.main() },
  },
  // not working. used to green flash on fill
  newRow: {
    animationName: 'newRow',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes newRow': {
    from: { backgroundColor: theme.palette.background.green },
    to: { backgroundColor: 'transparent' },
  },
});

class MyTradesTable extends Component {
  constructor(props) {
    super(props);
    // not working. used to green flash on fill
    this.state = {
      arrLength: 0,
      indexToFlash: 0,
      needFlash: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // not working. used to green flash on fill
    if (nextProps.trades.length - prevState.arrLength === 1) {
      const res = findMostRecentIndex(nextProps.trades);
      debug('MyTradesTable res', res);
      return {
        arrLength: nextProps.trades.length,
        indexToFlash: res.index,
        needFlash: true,
      };
    }
    return {
      arrLength: nextProps.trades.length || 0,
      indexToFlash: 0,
      needFlash: false,
    };
  }
  render() {
    const { classes, trades, isLoading, chainId } = this.props;
    const { needFlash, indexToFlash } = this.state;

    const getCellProps = (trade, index) => ({
      className: classNames(
        trade.status === 'filling' && classes.filling,
        trade.status !== 'filling' &&
          needFlash &&
          index === indexToFlash &&
          classes.newRow,
      ),
    });

    const columns = [
      {
        header: 'Price',
        headerMaxValue: () => '0.000000000',
        cell: (e) => (
          <RlcDisplay amount={e.workerpool.price} displayUnit={false} />
        ),
        getCellProps,
      },
      {
        header: 'Time',
        headerMaxValue: () => '00:00:00',
        cell: (e) => new Date(e.blockTimestamp).toLocaleTimeString(),
        getCellProps,
      },
      {
        header: 'Workerpool',
        headerMaxValue: () => NULL_ADDRESS,
        cell: (e) => e.workerpool.pointer,
        getCellProps,
      },
      {
        header: 'App',
        headerMaxValue: () => NULL_ADDRESS,
        cell: (e) => e.app.pointer,
        getCellProps,
      },
      {
        header: 'Dataset',
        headerMaxValue: () => NULL_ADDRESS,
        cell: (e) => e.dataset.pointer,
        getCellProps,
      },
      {
        header: 'Volume',
        headerMaxValue: (data) => Math.max(...data.map((e) => e.volume)),
        cell: (e) => e.volume,
        getCellProps,
      },
    ];

    return (
      <Table
        data={trades}
        loading={isLoading}
        columns={columns}
        emptyContent={'No Trades'}
        getRowProps={(trade, index) => ({
          onClick: () =>
            window.open(
              `${EXPLORER_BASE_URL}/${getChainKey(chainId)}/deal/${
                trade.dealid
              }`,
              '_blank',
            ),
        })}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  chainId: state.chain.chainId,
  trades: state.data.myTrades,
  isLoading: state.isLoading['GET_MY_TRADES'],
});

export default connect(mapStateToProps)(withStyles(styles)(MyTradesTable));
