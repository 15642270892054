import React from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormLabel from '@material-ui/core/FormLabel';
import Collapse from '@material-ui/core/Collapse';
import { BeatLoader } from 'react-spinners';
import classNames from 'classnames';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { handleLimitOrderSellFormChange } from '../store/actions/form';
import { placeSellLimitAsync } from '../store/actions/user';
import Button from './Button';
import TagContainer from './TagContainer';
import { rlcToNano } from '../utils/maths';
import RlcDisplay from './RlcDisplay';

const debug = Debug('LimitSell');

const stateToProps = (state) => ({
  hasInputError: state.limitOrderSellForm.errors,
});
const Tooltip = connect(stateToProps)(
  ({ hasInputError, children, fieldName, className }) => {
    if (!!hasInputError[fieldName])
      return React.cloneElement(children, {
        error: true,
        className: className || 'hint--bottom',
        'aria-label': hasInputError[fieldName],
      });
    return children;
  },
);

const styles = (theme) => ({
  box: {
    flex: '1 0 350px',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0 0 3rem 0',
    padding: '0 1rem',
    overflow: 'auto',
  },
  spacer: {
    flex: 1,
  },
  container: {
    flex: 2,
  },
  button: {
    width: '100%',
    textAlign: 'center',
  },
  sell: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  disabled: {
    cursor: 'not-allowed',
  },
  arrow: {
    marginLeft: '20px',
  },
  collapse: {
    display: 'flex',
    flex: '1 0 auto',
    width: '100%',
  },
  collapseWrapper: {
    width: '100%',
    paddingLeft: '20px',
  },
});

const LimitSell = ({
  isAdvancedParamsOpen,
  isLoading,
  classes,
  workerpoolAddress,
  price,
  volume,
  trust,
  tag,
  category,
  handleWorkerpoolAddressChange,
  handlePriceChange,
  handleVolumeChange,
  handleTrustChange,
  handleTagChange,
  toggleAdvancedParams,
  hasInputError,
  theme,
  placeSellLimit,
}) => {
  const isSellFormValid =
    !hasInputError.WORKERPOOL_ADDRESS &&
    !hasInputError.PRICE &&
    !hasInputError.VOLUME &&
    !hasInputError.TRUST &&
    !!workerpoolAddress &&
    !!price &&
    !!volume &&
    !!trust &&
    !!tag;
  const confirmPlace = () => {
    if (isSellFormValid) {
      placeSellLimit({
        workerpoolAddress,
        price: rlcToNano(price),
        volume,
        category,
        trust,
        tag,
      });
    }
  };
  const handleKeyboardSubmit = (fnName) => (e) => {
    if (e.which === 13 && e.nativeEvent.ctrlKey) {
      debug('ctrl + enter');
      if (fnName === 'placeOrder') {
        confirmPlace();
      }
    }
  };

  return (
    <div className={classes.box}>
      <Tooltip fieldName="WORKERPOOL_ADDRESS">
        <FormControl required>
          <FormLabel>{'Workerpool Address:'}</FormLabel>
          <Input
            value={workerpoolAddress}
            onChange={handleWorkerpoolAddressChange}
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="VOLUME">
        <FormControl required>
          <FormLabel>{'Volume:'}</FormLabel>
          <Input
            value={volume}
            onChange={handleVolumeChange}
            autoComplete="on"
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>
      <Tooltip fieldName="PRICE">
        <FormControl required>
          <FormLabel>{'Price:'}</FormLabel>
          <Input
            value={price}
            onChange={handlePriceChange}
            autoComplete="on"
            endAdornment={
              <InputAdornment position="end">
                <RlcDisplay />
              </InputAdornment>
            }
            onKeyDown={handleKeyboardSubmit('placeOrder')}
          />
        </FormControl>
      </Tooltip>
      <FormControl>
        <FormLabel onClick={toggleAdvancedParams}>
          <span className="">Advanced parameters</span>
          {isAdvancedParamsOpen ? (
            <FaAngleUp className={classes.arrow} />
          ) : (
            <FaAngleDown className={classes.arrow} />
          )}
        </FormLabel>
      </FormControl>
      <Collapse
        in={isAdvancedParamsOpen}
        timeout="auto"
        classes={{
          root: classes.collapse,
          wrapper: classes.collapseWrapper,
        }}
      >
        <Tooltip fieldName="TRUST">
          <FormControl required>
            <FormLabel>{'Trust:'}</FormLabel>
            <Input
              value={trust}
              onChange={handleTrustChange}
              placeholder={'offered trust level (integer)'}
              autoComplete="on"
              onKeyDown={handleKeyboardSubmit('placeOrder')}
            />
          </FormControl>
        </Tooltip>
        <Tooltip fieldName="TAG">
          <FormControl required onKeyDown={handleKeyboardSubmit('placeOrder')}>
            <FormLabel>{'Tag:'}</FormLabel>
            <TagContainer
              className={classes.container}
              tag={tag}
              onTagChange={handleTagChange}
            />
          </FormControl>
        </Tooltip>
      </Collapse>
      <Button
        onClick={confirmPlace}
        aria-label="fill in sell form first"
        className={classNames(
          classes.button,
          classes.sell,
          !isSellFormValid && classes.disabled,
          !isSellFormValid && 'hint--top',
        )}
      >
        {isLoading ? (
          <BeatLoader size="0.4em" color="#000" />
        ) : (
          'Place computation sell limit order'
        )}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdvancedParamsOpen: state.limitOrderSellForm.isAdvancedParamsOpen,
  workerpoolAddress: state.limitOrderSellForm.workerpoolAddress,
  price: state.limitOrderSellForm.price,
  volume: state.limitOrderSellForm.volume,
  trust: state.limitOrderSellForm.trust,
  tag: state.limitOrderSellForm.tag,
  category: state.data.filters.category,
  hasInputError: state.limitOrderSellForm.errors,
  isLoading: state.isLoading['PLACE_SELL_LIMIT_ORDER'],
});

const mapDispatchToProps = (dispatch) => ({
  handleWorkerpoolAddressChange: (event) =>
    dispatch(
      handleLimitOrderSellFormChange('WORKERPOOL_ADDRESS')(event.target.value),
    ),
  handlePriceChange: (event) =>
    dispatch(handleLimitOrderSellFormChange('PRICE')(event.target.value)),
  handleVolumeChange: (event) =>
    dispatch(handleLimitOrderSellFormChange('VOLUME')(event.target.value)),
  handleTrustChange: (event) =>
    dispatch(handleLimitOrderSellFormChange('TRUST')(event.target.value)),
  handleTagChange: (tag) =>
    dispatch(handleLimitOrderSellFormChange('TAG')(tag)),
  toggleAdvancedParams: () =>
    dispatch(handleLimitOrderSellFormChange('TOGGLE_ADVANCED')()),
  placeSellLimit: (args) => dispatch(placeSellLimitAsync.request(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(withStyles(styles)(LimitSell)));
