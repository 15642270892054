import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useWalletManager } from '@iexec/react-wallet-manager';
import Select from './Select';
import {
  changeChain,
  providerNetworkChanged,
  providerAccountChanged,
} from '../store/actions/chain';
import { mediaQueries } from '../utils/display';
import {
  chains,
  chainNames,
  isSupportedChain,
  UNSUPPORTED_CHAIN,
} from '../utils/chain';

const styles = (theme) => ({
  root: {
    margin: '0 10px',
    gridArea: 'controls',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [mediaQueries.medium.up]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  controlContainerGroup: {
    display: 'flex',
    flexDirection: 'row',
    '&>div': {
      margin: '8px',
    },
  },
  select: {
    width: '16em',
  },
});

const ControlBar = ({ classes, chainId, changeChain, changeAccount }) => {
  const {
    connected,
    chainId: walletChainId,
    changeNetwork,
    address,
  } = useWalletManager();

  // update chain state on wallet change
  useEffect(() => {
    if (walletChainId && chainId !== walletChainId) {
      changeChain(walletChainId);
    }
  }, [changeChain, chainId, walletChainId]);

  useEffect(() => {
    changeAccount(address);
  }, [address, changeAccount]);

  const connectedChain = walletChainId || chainId;

  const chainName = isSupportedChain(connectedChain)
    ? chains[connectedChain] && chains[connectedChain].name
    : UNSUPPORTED_CHAIN;

  const getDisplayName = (name) =>
    (chains[name] && (chains[name].shortDesc || chains[name].desc)) || name;

  const allChainNames =
    connected && !isSupportedChain(walletChainId)
      ? [UNSUPPORTED_CHAIN, ...chainNames]
      : chainNames;

  const selectChainNames = allChainNames.map((name) => ({
    value: name,
    label: getDisplayName(name),
  }));
  return (
    <div className={classes.root}>
      <div className={classes.controlContainerGroup}>
        <Select
          className={classes.select}
          options={selectChainNames}
          value={{
            value: chainName,
            label: getDisplayName(chainName),
          }}
          onChange={(evt) =>
            connected
              ? changeNetwork(chains[evt.value]?.id)
              : changeChain(evt.value)
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chainId: state.chain.chainId,
});

const mapDispatchToProps = (dispatch) => ({
  changeChain: (chainName) => {
    dispatch(changeChain(chainName));
    dispatch(providerNetworkChanged());
  },
  changeAccount: (address) => dispatch(providerAccountChanged()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(ControlBar)));
