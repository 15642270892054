import Debug from 'debug';
import createWS from 'socket.io-client';

const debug = Debug('service:websocket');

export const getWebsocket = (wsUrl, options = {}) => {
  const ws = createWS(wsUrl, options);
  ws.on('connect', () => debug('connect', wsUrl));
  ws.on('disconnect', () => debug('disconnect', wsUrl));
  ws.on('connect_error', error => debug('connect_error: ', error));
  ws.on('connect_timeout', () => debug('connect_timeout'));
  ws.on('error', error => debug('error: ', error));
  ws.on('reconnect', attempt => debug('reconnect: ', attempt));
  ws.on('reconnect_attempt', attempt => debug('reconnect_attempt: ', attempt));
  ws.on('reconnecting', attempt => debug('reconnecting: ', attempt));
  ws.on('reconnect_error', error => debug('reconnect_error: ', error));
  ws.on('reconnect_failed', () => debug('reconnect_failed'));
  return ws;
};
