import Debug from 'debug';
import { combineReducers } from 'redux';
import {
  validateInt,
  validateRlcAmount,
  validateAddress,
  validateNotNullInt,
  validateBytes32,
} from '../../utils/validation';
import { NULL_BYTES32 } from '../../utils/ethereum';

const debug = Debug('limitOrderSellForm');

function errors(state = {}, action) {
  if (
    action.type.includes('SET_LIMIT_ORDER_SELL_FORM_') &&
    action.type.includes('_FIELD')
  ) {
    debug('action.type', action.type);
    const key = action.type
      .split('SET_LIMIT_ORDER_SELL_FORM_')[1]
      .split('_FIELD')[0];
    debug('key', key);
    switch (action.type) {
      case 'SET_LIMIT_ORDER_SELL_FORM_WORKERPOOL_ADDRESS_FIELD':
        return { ...state, [key]: validateAddress(action.value) };
      case 'SET_LIMIT_ORDER_SELL_FORM_PRICE_FIELD':
        return { ...state, [key]: validateRlcAmount(action.value) };
      case 'SET_LIMIT_ORDER_SELL_FORM_VOLUME_FIELD':
        return { ...state, [key]: validateNotNullInt(action.value) };
      case 'SET_LIMIT_ORDER_SELL_FORM_TRUST_FIELD':
        return { ...state, [key]: validateInt(action.value) };
      case 'SET_LIMIT_ORDER_SELL_FORM_TAG_FIELD':
        return { ...state, [key]: validateBytes32(action.value) };
      default:
        return state;
    }
  }
  return state;
}

function workerpoolAddress(state = '', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_SELL_FORM_WORKERPOOL_ADDRESS_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '';
    default:
      return state;
  }
}

function volume(state = '1', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_SELL_FORM_VOLUME_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '1';
    default:
      return state;
  }
}

function price(state = '', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_SELL_FORM_PRICE_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '';
    default:
      return state;
  }
}

function trust(state = '0', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_SELL_FORM_TRUST_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '0';
    default:
      return state;
  }
}

function tag(state = NULL_BYTES32, action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_SELL_FORM_TAG_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return NULL_BYTES32;
    default:
      return state;
  }
}

function isAdvancedParamsOpen(state = false, action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_SELL_FORM_TOGGLE_ADVANCED_FIELD':
      return !state;
    default:
      return state;
  }
}

export default combineReducers({
  errors,
  workerpoolAddress,
  volume,
  price,
  trust,
  tag,
  isAdvancedParamsOpen,
});
