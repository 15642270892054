import React, { Component } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { IoMdClose } from 'react-icons/io';
import classNames from 'classnames';
import { Table } from './Table';
import RlcDisplay from './RlcDisplay';
import { cancelWorkerpoolOrderAsync } from '../store/actions/user';
import { findMostRecentIndex } from '../utils/filterAndSort';
import { NULL_ADDRESS } from '../utils/ethereum';

const debug = Debug('MyWorkerpoolOrdersTable');

const styles = (theme) => ({
  cancelCell: {
    textAlign: 'center',
  },
  cancelButton: {
    padding: '0.2em',
    fontSize: '0.8em',
    display: 'inline-block',
    borderRadius: '50%',
    color: theme.palette.background.red,
    backgroundColor: '#262626',
  },
  volume: {
    color: theme.palette.background.red,
  },
  center: {
    textAlign: 'center',
  },
  placing: {
    animationName: 'placing',
    animationDuration: '3s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  '@keyframes placing': {
    '0%': { backgroundColor: theme.palette.background.main() },
    '50%': { backgroundColor: 'transparent' },
    '100%': { backgroundColor: theme.palette.background.main() },
  },
  cancelling: {
    animationName: 'cancelling',
    animationDuration: '3s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  '@keyframes cancelling': {
    '0%': { backgroundColor: theme.palette.background.red },
    '50%': { backgroundColor: 'transparent' },
    '100%': { backgroundColor: theme.palette.background.red },
  },
  newRow: {
    animationName: 'newRow',
    animationDuration: '2s',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes newRow': {
    from: { backgroundColor: theme.palette.background.green },
    to: { backgroundColor: 'transparent' },
  },
});

class MyWorkerpoolOrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrLength: 0,
      indexToFlash: 0,
      needFlash: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.orders.length - prevState.arrLength === 1) {
      const res = findMostRecentIndex(nextProps.orders);
      debug('MyWorkerpoolOrdersTable res', res);
      return {
        arrLength: nextProps.orders.length,
        indexToFlash: res.index,
        needFlash: true,
      };
    }
    return {
      arrLength: nextProps.orders.length || 0,
      indexToFlash: 0,
      needFlash: false,
    };
  }
  render() {
    const { classes, orders, isLoading, cancelOrder } = this.props;
    const { needFlash, indexToFlash } = this.state;
    debug('orders', orders);

    const getCellProps = (order, index) => ({
      className: classNames(
        order.status === 'placing' && classes.placing,
        order.status !== 'placing' &&
          needFlash &&
          index === indexToFlash &&
          classes.newRow,
        order.status === 'cancelling' && classes.cancelling,
      ),
    });

    const columns = [
      {
        header: 'Workerpool',
        headerMaxValue: () => NULL_ADDRESS,
        cell: (e) => e.order.workerpool,
        getCellProps,
      },
      {
        header: 'Trust',
        headerMaxValue: (data) => Math.max(...data.map((e) => e.order.trust)),
        cell: (e) => e.order.trust,
        getCellProps,
      },
      {
        header: 'Price',
        headerMaxValue: (data) => '0.000000000',
        cell: (e) => (
          <RlcDisplay amount={e.order.workerpoolprice} displayUnit={false} />
        ),
        getCellProps,
      },
      {
        header: 'Remaining',
        headerMaxValue: (data) => {
          const max = Math.max(...data.map((e) => e.order.volume));
          return `${max}/${max}`;
        },
        cell: (e) => `${e.remaining}/${e.order.volume}`,
        getCellProps,
      },
      {
        header: 'Cancel',
        cell: (e) => (
          <div className={classes.cancelButton}>
            <IoMdClose />
          </div>
        ),
        className: classes.cancelCell,
        getCellProps: (order, index) => ({
          ...getCellProps(order, index),
          onClick: () => cancelOrder(order.orderHash, order.order),
        }),
      },
    ];

    return (
      <Table
        data={orders}
        loading={isLoading}
        columns={columns}
        emptyContent={'No Orders'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.data.myWorkerpoolOrders,
  isLoading: state.isLoading['GET_MY_ORDERS'],
});

const mapDispatchToProps = (dispatch) => ({
  cancelOrder: (orderHash, order) =>
    dispatch(cancelWorkerpoolOrderAsync.request(orderHash, order)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MyWorkerpoolOrdersTable));
