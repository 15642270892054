import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { decodeTag, encodeTag } from 'iexec/utils';
import { TAG_LABELS } from '../utils/iexecOrders';

const styles = (theme) => ({
  label: {
    display: 'inline-block',
    padding: '0 10px',
  },
  checkbox: {
    padding: 0,
  },
});

const TagContainer = ({ tag, onTagChange, classes, className }) => {
  const tags = decodeTag(tag);
  const handleChange = (changed) => (changedTag, checked) => {
    const prevTags = decodeTag(tag);
    if (checked) {
      return onTagChange(encodeTag(prevTags.concat([changed])));
    } else {
      return onTagChange(encodeTag(prevTags.filter((e) => e !== changed)));
    }
  };

  return (
    <div className={className}>
      {Object.keys(TAG_LABELS).map((e) => (
        <label key={e} className={classes.label}>
          {TAG_LABELS[e]}
          {'   '}
          <Checkbox
            className={classes.checkbox}
            type="checkbox"
            checked={tags.includes(e)}
            onChange={handleChange(e)}
          />
        </label>
      ))}
    </div>
  );
};

export default withTheme(withStyles(styles)(TagContainer));
