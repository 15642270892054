import Debug from 'debug';
import { combineReducers } from 'redux';
import {
  validateInt,
  validateRlcAmount,
  validateAddress,
  validateUrl,
  validateNotNullInt,
  validateBytes32,
} from '../../utils/validation';
import { NULL_ADDRESS, NULL_BYTES32 } from '../../utils/ethereum';
import { DEFAULT_STORAGE_PROVIDER } from '../../utils/iexecSecrets';

const debug = Debug('limitOrderBuyForm');

function errors(state = {}, action) {
  if (
    action.type.includes('SET_LIMIT_ORDER_BUY_FORM_') &&
    action.type.includes('_FIELD')
  ) {
    debug('action.type', action.type);
    const key = action.type
      .split('SET_LIMIT_ORDER_BUY_FORM_')[1]
      .split('_FIELD')[0];
    debug('key', key);
    switch (action.type) {
      case 'SET_LIMIT_ORDER_BUY_FORM_DAPP_ADDRESS_FIELD':
        return { ...state, [key]: validateAddress(action.value.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_DATASET_ADDRESS_FIELD':
        return { ...state, [key]: validateAddress(action.value.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_BENEFICIARY_ADDRESS_FIELD':
        return { ...state, [key]: validateAddress(action.value.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_DAPP_PRICE_FIELD':
        return { ...state, [key]: validateRlcAmount(action.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_DATASET_PRICE_FIELD':
        return { ...state, [key]: validateRlcAmount(action.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_WORKERPOOL_PRICE_FIELD':
        return { ...state, [key]: validateRlcAmount(action.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_VOLUME_FIELD':
        return { ...state, [key]: validateNotNullInt(action.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_TRUST_FIELD':
        return { ...state, [key]: validateInt(action.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_TAG_FIELD':
        return { ...state, [key]: validateBytes32(action.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_CALLBACK_FIELD':
        return { ...state, [key]: validateAddress(action.value) };
      case 'SET_LIMIT_ORDER_BUY_FORM_PARAMS_INPUT_FILES_FIELD':
        return {
          ...state,
          [`${key}_${action.value.index}`]: validateUrl(action.value.value),
        };
      default:
        return state;
    }
  }
  return state;
}

function dappAddress(
  state = {
    value: '',
    label: '',
  },
  action,
) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_DAPP_ADDRESS_FIELD':
      return action.value;
    default:
      return state;
  }
}

function dappPrice(state = '0', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_DAPP_PRICE_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '0';
    default:
      return state;
  }
}

function datasetAddress(
  state = {
    value: NULL_ADDRESS,
    label: '',
  },
  action,
) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_DATASET_ADDRESS_FIELD':
      return action.value;
    default:
      return state;
  }
}

function beneficiaryAddress(
  state = {
    value: '',
    label: '',
  },
  action,
) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_BENEFICIARY_ADDRESS_FIELD':
      return action.value;
    default:
      return state;
  }
}

function datasetPrice(state = '0', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_DATASET_PRICE_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '0';
    default:
      return state;
  }
}

function workerpoolPrice(state = '0', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_WORKERPOOL_PRICE_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '0';
    default:
      return state;
  }
}

function paramsArgs(state = '', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_PARAMS_ARGS_FIELD':
      return action.value;
    default:
      return state;
  }
}

function paramsStorageProvider(state = DEFAULT_STORAGE_PROVIDER, action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_PARAMS_STORAGE_PROVIDER_FIELD':
      return action.value;
    default:
      return state;
  }
}

function paramsInputFiles(state = [], action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_PARAMS_INPUT_FILES_FIELD':
      const newState = state.slice();
      newState[action.value.index] = action.value.value;
      return newState.filter((e) => !!e);
    default:
      return state;
  }
}

function paramsResultEncryption(state = false, action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_PARAMS_ENCRYPT_FIELD':
      return action.value;
    default:
      return state;
  }
}

function volume(state = '1', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_VOLUME_FIELD':
      return action.value;
    case 'CLEAR_ORDER_FORM':
      return '1';
    default:
      return state;
  }
}

function trust(state = '', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_TRUST_FIELD':
      return action.value;
    default:
      return state;
  }
}

function tag(state = NULL_BYTES32, action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_TAG_FIELD':
      return action.value;
    default:
      return state;
  }
}

function callbackAddress(state = '', action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_CALLBACK_FIELD':
      return action.value;
    default:
      return state;
  }
}

function isAdvancedParamsOpen(state = false, action) {
  switch (action.type) {
    case 'SET_LIMIT_ORDER_BUY_FORM_TOGGLE_ADVANCED_FIELD':
      return !state;
    default:
      return state;
  }
}

export default combineReducers({
  errors,
  dappAddress,
  datasetAddress,
  beneficiaryAddress,
  dappPrice,
  datasetPrice,
  workerpoolPrice,
  volume,
  paramsArgs,
  paramsInputFiles,
  paramsResultEncryption,
  paramsStorageProvider,
  trust,
  tag,
  callbackAddress,
  isAdvancedParamsOpen,
});
