import Debug from 'debug';
import {
  call,
  all,
  put,
  takeLatest,
  take,
  race,
  cancel,
  fork,
  cancelled,
} from 'redux-saga/effects';
import BN from 'bn.js';
import IExecConfig from 'iexec/IExecConfig';
import IExecStorage from 'iexec/IExecStorageModule';
import IExecOrder from 'iexec/IExecOrderModule';
import IExecOrderbook from 'iexec/IExecOrderbookModule';
import { sumTags } from 'iexec/utils';
import { getWeb3, multiWeb3 } from '../../services/web3';
import * as userActions from '../actions/user';
import * as storageActions from '../actions/storage';
import * as notifierActions from '../actions/notifier';
import { NULL_ADDRESS, NULL_BYTES32 } from '../../utils/ethereum';
import { minBn } from '../../utils/maths';
import {
  NULL_DATASETORDER,
  TEE_TAG,
  isTeeRequired,
} from '../../utils/iexecOrders';
import { capitalizeFirstLetter } from '../../utils/format';
import {
  DEFAULT_STORAGE_PROVIDER,
  CALLBACK_STORAGE_PROVIDER,
} from '../../utils/iexecSecrets';
import {
  PROVIDER_ACCOUNT_CHANGED,
  PROVIDER_NETWORK_CHANGED,
} from '../types/chain';

const debug = Debug('sagas:user');

function cancelable(cancelActions, saga) {
  return function* (...args) {
    const task = yield fork(saga, ...args);
    yield race(cancelActions.map((cancelAction) => take(cancelAction)));
    yield cancel(task);
  };
}

function* getBestAppOrder(
  appAddress,
  { dataset, workerpool, requester, minTag, maxTag } = {},
) {
  try {
    debug('getBestAppOrder()');
    const { chainId } = multiWeb3.getStatus();
    const config = new IExecConfig({ ethProvider: chainId });
    const iexecOrderbook = IExecOrderbook.fromConfig(config);
    const appOrderbook = yield call(
      iexecOrderbook.fetchAppOrderbook,
      appAddress,
      {
        chainId,
        appAddress,
        dataset,
        workerpool,
        requester,
        minTag,
        maxTag,
      },
    );
    const [bestOrder] = appOrderbook.orders;
    if (bestOrder && bestOrder.order) return bestOrder;
    return null;
  } catch (error) {
    debug('getBestAppOrder()', error);
    throw error;
  }
}

function* getBestDatasetOrder(
  datasetAddress,
  {
    app = NULL_ADDRESS,
    workerpool = NULL_ADDRESS,
    requester = NULL_ADDRESS,
    minTag,
    maxTag,
  } = {},
) {
  try {
    if (datasetAddress === NULL_ADDRESS) {
      const bestOrder = {
        order: NULL_DATASETORDER,
        remaining: '999999999999',
      };
      return bestOrder;
    } else {
      const { chainId } = multiWeb3.getStatus();
      const config = new IExecConfig({ ethProvider: chainId });
      const iexecOrderbook = IExecOrderbook.fromConfig(config);
      const datasetOrderbook = yield call(
        iexecOrderbook.fetchDatasetOrderbook,
        datasetAddress,
        {
          chainId,
          dataset: datasetAddress,
          workerpool,
          app,
          requester,
          minTag,
          maxTag,
        },
      );
      const [bestOrder] = datasetOrderbook.orders;
      if (bestOrder && bestOrder.order) return bestOrder;
      return null;
    }
  } catch (error) {
    debug('getBestDatasetOrder()', error);
    throw error;
  }
}

function computeParamsFromWorkParams(workParams) {
  const isCallback = workParams.storageProvider === CALLBACK_STORAGE_PROVIDER;
  return {
    iexec_args: workParams.args || undefined,
    iexec_input_files:
      (workParams.inputFiles &&
        workParams.inputFiles.length > 0 &&
        workParams.inputFiles) ||
      undefined,
    iexec_result_encryption: !!workParams.resultEncryption,
    iexec_result_storage_provider: !isCallback
      ? workParams.storageProvider || DEFAULT_STORAGE_PROVIDER
      : undefined,
  };
}

export function* confirmBuyMarket(action) {
  try {
    debug('confirmBuyMarket()', action);
    const {
      workerpoolOrderHash,
      appAddress,
      datasetAddress,
      volume,
      workParams,
      tag,
      trust,
      beneficiaryAddress,
      callback = NULL_ADDRESS,
    } = action;
    const web3 = getWeb3();
    const { account: requesterAddress } = multiWeb3.getStatus();
    const config = new IExecConfig({ ethProvider: web3 });
    const iexecOrder = IExecOrder.fromConfig(config);
    const iexecOrderbook = IExecOrderbook.fromConfig(config);
    const isCallback = workParams.storageProvider === CALLBACK_STORAGE_PROVIDER;
    // ensure storage initialized
    if (!isCallback) {
      const storageProvider =
        workParams.storageProvider || DEFAULT_STORAGE_PROVIDER;
      const iexecStorage = IExecStorage.fromConfig(config);
      const storageInitialized = yield call(
        iexecStorage.checkStorageTokenExists,
        requesterAddress,
      );
      if (!storageInitialized) {
        yield put(
          storageActions.openStorageModal({
            storageProvider,
            successAction: action,
            failureAction: userActions.confirmBuyMarketAsync.failure(
              'Storage not initialized',
            ),
          }),
        );
        return;
      }
    }
    const workerpoolOrderRes = yield call(
      iexecOrderbook.fetchWorkerpoolorder,
      workerpoolOrderHash,
    );
    const teeRequired = isTeeRequired(tag);
    const datasetOrderRes = yield getBestDatasetOrder(datasetAddress, {
      app: appAddress,
      workerpool: workerpoolOrderRes.order.workerpool,
      requester: requesterAddress,
      maxTag: tag,
      minTag: teeRequired ? TEE_TAG : NULL_BYTES32,
    });
    if (!datasetOrderRes)
      throw new Error(
        `Missing datasetorder ${
          teeRequired ? 'TEE enabled ' : ''
        } for dataset ${datasetAddress}`,
      );
    const appOrderRes = yield getBestAppOrder(appAddress, {
      dataset: datasetAddress,
      requester: requesterAddress,
      workerpool: workerpoolOrderRes.order.workerpool,
      minTag: teeRequired ? TEE_TAG : NULL_BYTES32,
      maxTag: tag,
    });
    if (!appOrderRes)
      throw new Error(
        `Missing apporder ${
          teeRequired ? 'TEE enabled ' : ''
        } for dapp ${appAddress}`,
      );
    if (!workerpoolOrderRes) throw new Error('Missing workerpoolorder');
    const requestOrderToSign = yield call(iexecOrder.createRequestorder, {
      app: appOrderRes.order.app,
      appmaxprice: appOrderRes.order.appprice,
      dataset: datasetOrderRes.order.dataset,
      datasetmaxprice: datasetOrderRes.order.datasetprice,
      workerpool: workerpoolOrderRes.order.workerpool,
      workerpoolmaxprice: workerpoolOrderRes.order.workerpoolprice,
      volume: volume,
      tag: tag,
      category: workerpoolOrderRes.order.category,
      trust: trust,
      beneficiary: beneficiaryAddress,
      callback: callback || NULL_ADDRESS,
      params: computeParamsFromWorkParams(workParams),
    });
    yield put(
      userActions.confirmBuyMarketAsync.success(
        appOrderRes.order,
        datasetOrderRes.order,
        workerpoolOrderRes.order,
        requestOrderToSign,
      ),
    );
  } catch (error) {
    debug('confirmBuyMarket()', error);
    yield put(userActions.confirmBuyMarketAsync.failure(error.message));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.confirmBuyMarketAsync.cancelled());
    }
  }
}

export function* makeBuyDeal(action) {
  try {
    debug('makeBuyDeal()', action);
    const { appOrder, datasetOrder, workerpoolOrder, requestOrderToSign } =
      action;
    const { provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const iexecOrder = IExecOrder.fromConfig(config);
    yield put(
      notifierActions.notify({
        message: `Open ${capitalizeFirstLetter(
          provider,
        )} to sign your requestorder`,
      }),
    );
    const requestOrder = yield call(
      iexecOrder.signRequestorder,
      requestOrderToSign,
    );
    yield put(
      notifierActions.notify({
        message: `2/2 Open ${capitalizeFirstLetter(provider)} to make the deal`,
      }),
    );
    const { txHash } = yield call(iexecOrder.matchOrders, {
      apporder: appOrder,
      datasetorder: datasetOrder,
      workerpoolorder: workerpoolOrder,
      requestorder: requestOrder,
    });
    yield put(userActions.makeBuyDealAsync.success(txHash));
  } catch (error) {
    debug('makeBuyDeal()', error);
    yield put(userActions.makeBuyDealAsync.failure(error.message));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.makeBuyDealAsync.cancelled());
    }
  }
}

export function* confirmSellMarket(action) {
  try {
    debug('confirmSellMarket()', action);
    const { requestOrderHash, workerpoolAddress, volume } = action;
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const iexecOrder = IExecOrder.fromConfig(config);
    const iexecOrderbook = IExecOrderbook.fromConfig(config);
    const requestOrderRes = yield call(
      iexecOrderbook.fetchRequestorder,
      requestOrderHash,
    );
    const datasetOrderRes = yield getBestDatasetOrder(
      requestOrderRes.order.dataset,
      {
        app: requestOrderRes.order.app,
        workerpool: workerpoolAddress,
        requester: requestOrderRes.order.requester,
      },
    );
    if (!datasetOrderRes)
      throw new Error(
        `Missing datasetorder for dataset ${requestOrderRes.order.dataset}`,
      );
    const teeAppRequired = isTeeRequired(
      requestOrderRes.order.tag,
      datasetOrderRes.order.tag,
    );
    const minAppTag = teeAppRequired ? TEE_TAG : NULL_BYTES32;
    const appOrderRes = yield getBestAppOrder(requestOrderRes.order.app, {
      dataset: requestOrderRes.order.dataset,
      requester: requestOrderRes.order.requester,
      workerpool: workerpoolAddress,
      minTag: minAppTag,
    });
    if (!appOrderRes)
      throw new Error(
        `Missing apporder ${teeAppRequired ? 'TEE enabled ' : ''} for dapp ${
          requestOrderRes.order.app
        }`,
      );
    const maxVolume = minBn([
      new BN(appOrderRes.remaining),
      new BN(datasetOrderRes.remaining),
      new BN(requestOrderRes.remaining),
    ]);
    const workerpoolVolume = new BN(volume);
    if (workerpoolVolume.gt(maxVolume))
      throw new Error(`Maximum volume available is ${maxVolume}`);

    const workerpoolOrderToSign = yield (iexecOrder.createWorkerpoolorder,
    {
      workerpool: workerpoolAddress,
      workerpoolprice: requestOrderRes.order.workerpoolmaxprice,
      volume: workerpoolVolume.toString(),
      tag: sumTags([
        requestOrderRes.order.tag,
        appOrderRes.order.tag,
        datasetOrderRes.order.tag,
      ]),
      category: requestOrderRes.order.category,
      trust: requestOrderRes.order.trust,
      apprestrict: appOrderRes.order.app,
      datasetrestrict: datasetOrderRes.order.dataset,
      requesterrestrict: requestOrderRes.order.requester,
    });
    yield put(
      userActions.confirmSellMarketAsync.success(
        appOrderRes.order,
        datasetOrderRes.order,
        workerpoolOrderToSign,
        requestOrderRes.order,
      ),
    );
  } catch (error) {
    debug('confirmSellMarket()', error);
    yield put(userActions.confirmSellMarketAsync.failure(error.message));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.confirmSellMarketAsync.cancelled());
    }
  }
}

export function* makeSellDeal(action) {
  try {
    debug('makeSellDeal()', action);
    const { appOrder, datasetOrder, workerpoolOrderToSign, requestOrder } =
      action;
    const { provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const iexecOrder = IExecOrder.fromConfig(config);
    yield put(
      notifierActions.notify({
        message: `1/2 Open ${capitalizeFirstLetter(
          provider,
        )} to sign your workerpoolorder`,
      }),
    );
    const workerpoolOrder = yield call(
      iexecOrder.signWorkerpoolorder,
      workerpoolOrderToSign,
    );
    yield put(
      notifierActions.notify({
        message: `2/2 Open ${capitalizeFirstLetter(provider)} to make the deal`,
      }),
    );
    const { txHash } = yield call(iexecOrder.matchOrders, {
      apporder: appOrder,
      datasetorder: datasetOrder,
      workerpoolorder: workerpoolOrder,
      requestorder: requestOrder,
    });
    yield put(userActions.makeSellDealAsync.success(txHash));
  } catch (error) {
    debug('makeSellDeal()', error);
    yield put(userActions.makeSellDealAsync.failure(error.message));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.makeSellDealAsync.cancelled());
    }
  }
}

export function* placeSellLimit(action) {
  try {
    debug('placeSellLimit()', action);
    const { provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const { workerpoolAddress, price, volume, trust, category, tag } = action;
    const iexecOrder = IExecOrder.fromConfig(config);
    const workerpoolOrderToSign = yield call(iexecOrder.createWorkerpoolorder, {
      workerpool: workerpoolAddress,
      price,
      volume,
      trust,
      category,
      tag,
    });
    yield put(
      notifierActions.notify({
        message: `Open ${capitalizeFirstLetter(
          provider,
        )} to sign your workerpoolorder`,
      }),
    );
    const workerpoolOrder = yield call(
      iexecOrder.signWorkerpoolorder,
      workerpoolOrderToSign,
    );
    yield put(
      notifierActions.notify({
        message: `Publishing your order, Open ${capitalizeFirstLetter(
          provider,
        )} to log in iExec gateway`,
      }),
    );
    const orderHash = yield call(
      iexecOrder.publishWorkerpoolorder,
      workerpoolOrder,
    );
    yield put(
      notifierActions.notify({
        message: `✓ Successfully publish workerpool order with Hash ${orderHash}`,
        level: 'success',
      }),
    );
    yield put(userActions.placeSellLimitAsync.success(orderHash));
  } catch (error) {
    debug('placeSellLimit()', 'error', error);
    yield put(userActions.placeSellLimitAsync.failure(error.message));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.placeSellLimitAsync.cancelled());
    }
  }
}

export function* placeBuyLimit(action) {
  try {
    debug('placeBuyLimit()', action);
    const { provider, account: requesterAddress } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const {
      appAddress,
      appPrice,
      datasetAddress,
      datasetPrice,
      workerpoolPrice,
      volume,
      workParams,
      trust,
      category,
      tag,
      beneficiaryAddress,
      callback,
    } = action;
    const isCallback = workParams.storageProvider === CALLBACK_STORAGE_PROVIDER;
    // ensure storage initialized
    if (!isCallback) {
      const storageProvider =
        workParams.storageProvider || DEFAULT_STORAGE_PROVIDER;
      const iexecStorage = IExecStorage.fromConfig(config);
      const storageInitialized = yield call(
        iexecStorage.checkStorageTokenExists,
        requesterAddress,
      );
      if (!storageInitialized) {
        yield put(
          storageActions.openStorageModal({
            storageProvider,
            successAction: action,
            failureAction: userActions.placeBuyLimitAsync.failure(
              'Storage not initialized',
            ),
          }),
        );
        return;
      }
    }
    const iexecOrder = IExecOrder.fromConfig(config);
    const requestOrderToSign = yield call(iexecOrder.createRequestorder, {
      app: appAddress,
      category,
      appPrice,
      dataset: datasetAddress,
      datasetPrice,
      workerpoolPrice,
      volume,
      params: computeParamsFromWorkParams(workParams),
      trust,
      tag,
      beneficiary: beneficiaryAddress,
      callback: callback || NULL_ADDRESS,
    });
    yield put(
      notifierActions.notify({
        message: `Open ${capitalizeFirstLetter(
          provider,
        )} to sign your requestorder`,
      }),
    );
    const requestOrder = yield call(
      iexecOrder.signRequestorder,
      requestOrderToSign,
    );
    yield put(
      notifierActions.notify({
        message: `Publishing your order, Open ${capitalizeFirstLetter(
          provider,
        )} to log in iExec gateway`,
      }),
    );
    const orderHash = yield call(iexecOrder.publishRequestorder, requestOrder);
    yield put(
      notifierActions.notify({
        message: `✓ Successfully publish request order with Hash ${orderHash}`,
        level: 'success',
      }),
    );
    yield put(userActions.placeBuyLimitAsync.success(orderHash));
  } catch (error) {
    debug('placeBuyLimit()', 'error', error);
    yield put(userActions.placeBuyLimitAsync.failure(error.message));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.placeBuyLimitAsync.cancelled());
    }
  }
}

export function* cancelRequestOrder(action) {
  try {
    debug('cancelRequestOrder()', action);
    const { order } = action;
    const { provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const iexecOrder = IExecOrder.fromConfig(config);
    const orderHash = yield call(iexecOrder.hashRequestorder, order);
    yield put(
      notifierActions.notify({
        message: `Open ${capitalizeFirstLetter(
          provider,
        )} to cancel your requestorder`,
      }),
    );
    yield call(iexecOrder.cancelRequestorder, order);
    yield put(
      notifierActions.notify({
        message: `✓ Successfully canceled requestorder ${orderHash}`,
        level: 'success',
      }),
    );
    yield put(userActions.cancelRequestOrderAsync.success(orderHash));
  } catch (error) {
    debug('cancelRequestOrder()', error);
    yield put(
      userActions.cancelRequestOrderAsync.failure(
        'Failed to cancel requestorder',
      ),
    );
  } finally {
    if (yield cancelled()) {
      yield put(userActions.cancelRequestOrderAsync.cancelled());
    }
  }
}

export function* cancelWorkerpoolOrder(action) {
  try {
    debug('cancelWorkerpoolOrder()', action);
    const { order } = action;
    const { provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const iexecOrder = IExecOrder.fromConfig(config);
    const orderHash = yield call(iexecOrder.hashWorkerpoolorder, order);
    yield put(
      notifierActions.notify({
        message: `Open ${capitalizeFirstLetter(
          provider,
        )} to cancel your workerpoolorder`,
      }),
    );
    yield call(iexecOrder.cancelWorkerpoolorder, order);
    yield put(
      notifierActions.notify({
        message: `✓ Successfully canceled workerpoolorder ${orderHash}`,
        level: 'success',
      }),
    );
    yield put(userActions.cancelWorkerpoolOrderAsync.success(orderHash));
  } catch (error) {
    debug('cancelWorkerpoolOrderAsync()', error);
    yield put(
      userActions.cancelWorkerpoolOrderAsync.failure(
        'Failed to cancel workerpoolorder',
      ),
    );
  } finally {
    if (yield cancelled()) {
      yield put(userActions.cancelWorkerpoolOrderAsync.cancelled());
    }
  }
}

export function* initStorage(action) {
  try {
    debug('initStorage()', action);
    const storageProvider = action.storageProvider;
    const { provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const iexecStorage = IExecStorage.fromConfig(config);
    let token;
    if (storageProvider === DEFAULT_STORAGE_PROVIDER) {
      yield put(
        notifierActions.notify({
          message: `Open ${capitalizeFirstLetter(
            provider,
          )} to request your storage token`,
        }),
      );
      token = yield call(iexecStorage.defaultStorageLogin);
    } else {
      token = action.storageToken;
    }
    yield put(
      notifierActions.notify({
        message: `Open ${capitalizeFirstLetter(
          provider,
        )} to push your storage token in the Secret Management Service`,
      }),
    );
    yield call(iexecStorage.pushStorageToken, token, {
      provider: storageProvider,
    });
    yield put(
      notifierActions.notify({
        message: `✓ Storage successfully initialized`,
        level: 'success',
      }),
    );
    yield put(userActions.initStorageAsync.success());
    if (action.successAction) {
      yield put(action.successAction);
    }
  } catch (error) {
    debug('initStorage()', error);
    yield put(userActions.initStorageAsync.failure(error.message));
    if (action.failureAction) {
      yield put(action.failureAction);
    }
  } finally {
    yield put(storageActions.closeStorageModal());
    if (yield cancelled()) {
      yield put(userActions.initStorageAsync.cancelled());
    }
  }
}

// SAGAS WATCHERS
export function* watchUser() {
  yield takeLatest(
    'INIT_STORAGE_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      initStorage,
    ),
  );
  yield takeLatest(
    'CONFIRM_BUY_MARKET_ORDER_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      confirmBuyMarket,
    ),
  );
  yield takeLatest(
    'BUY_DEAL_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      makeBuyDeal,
    ),
  );
  yield takeLatest(
    'CONFIRM_SELL_MARKET_ORDER_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      confirmSellMarket,
    ),
  );
  yield takeLatest(
    'SELL_DEAL_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      makeSellDeal,
    ),
  );
  yield takeLatest(
    'PLACE_SELL_LIMIT_ORDER_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      placeSellLimit,
    ),
  );
  yield takeLatest(
    'PLACE_BUY_LIMIT_ORDER_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      placeBuyLimit,
    ),
  );
  yield takeLatest(
    'CANCEL_WORKERPOOL_ORDER_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      cancelWorkerpoolOrder,
    ),
  );
  yield takeLatest(
    'CANCEL_REQUEST_ORDER_REQUEST',
    cancelable(
      [PROVIDER_NETWORK_CHANGED, PROVIDER_ACCOUNT_CHANGED],
      cancelRequestOrder,
    ),
  );
}

export default function* userSaga() {
  yield all([watchUser()]);
}
